import React from "react";
import UpdateBookingUserForm from "./UpdateBookingUserForm";

const UpdateBookingUser = () => {
  const handleSubmit = (values: any) => {};
  return (
    <UpdateBookingUserForm
    // onSubmit={handleSubmit}
    />
  );
};

export default UpdateBookingUser;
