import { config } from "config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TicketState } from ".";

const default_page_size = config.PAGE_SIZE;
const initialState: TicketState = {
  tickets: [],
  ticket: null,
  reasons: [],
  loading: true,
  current_filters: {},
  filters: { page: 1, pageSize: default_page_size },
  refresh: 0,
  refreshLoader: false,
  totalCount: 0,
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addTicket: (state, action) => {
      state.tickets.unshift(action.payload);
    },
    updateTicket: (state, action) => {
      const { id, ticket } = action.payload;
      state.tickets.every(({ _id }, i) => {
        if (id === _id) {
          state.tickets[i] = ticket;
          return false;
        }
        return true;
      });
    },
    deleteTicketById: (state, action) => {
      const id = action.payload;
      state.tickets.every(({ _id }, i) => {
        if (id === _id) {
          state.totalCount -= 1;
          state.tickets.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setTicket: (state: any, action: PayloadAction<any>) => {
      state.ticket = action.payload;
    },
    setTickets: (state: any, action: PayloadAction<any>) => {
      const { tickets } = action.payload;
      state.tickets = tickets;
    },

    setTotalCount: (state: any, action: PayloadAction<any>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
    },
    setPage: (state: any, action: PayloadAction<any>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state: any) => {
      state.filters.page = 1;
    },
    setFilters: (state: any, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state: any, action: PayloadAction<any>) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state: any) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const ticketReducer = ticketSlice.reducer;
export const ticketActions = ticketSlice.actions;
export default ticketReducer;
