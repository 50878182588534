import { useEffect, useState, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import SelectReactRedux from "components/molecules/SelectReactRedux";
import debounce from "lodash.debounce";
import CarModelService from "services/carModel.service";
import { carModelActions } from "redux/slices/carModel";

export default function SelectModels(props: any) {
  const dispatch = useAppDispatch();
  const models = useAppSelector((state) => state.carModel.carModelOptions);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const hasMore = useMemo(() => models.length % 30 === 0, [models]);

  useEffect(() => {
    loadUserOptions();

    return () => {
      dispatch(carModelActions.clear());
    };
  }, [dispatch]);

  const loadUserOptions = async (inputValue = "", newPage = 1) => {
    setLoading(true);
    await CarModelService.getCarModelOptions({
      page: newPage,
      pageSize: 30,
      ...(inputValue && { name: inputValue }),
    });
    setLoading(false);
    setPage(newPage);
  };

  const debouncedLoadUserOptions = useCallback(
    debounce(
      (
        inputValue: string,
        newPage: number,
        callback: (options: Array<{ value: string; label: string }>) => void
      ) => {
        loadUserOptions(inputValue, newPage).then(() => {
          callback(models);
        });
      },
      300
    ),
    [models]
  );

  const handleLoadOptions = (
    inputValue: string,
    callback: (options: Array<{ value: string; label: string }>) => void
  ) => {
    setInputValue(inputValue);
    debouncedLoadUserOptions(inputValue, 1, callback);
  };

  const handleMenuScrollToBottom = () => {
    if (hasMore) {
      loadUserOptions("", page + 1);
    }
  };

  const handleChange = (value: any) => {
    setInputValue("");
    props.input.onChange(value);
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    if (newValue === "") {
      loadUserOptions("", 1);
    }
  };

  return (
    <SelectReactRedux
      {...props}
      options={models}
      loadOptions={handleLoadOptions}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      isLoading={loading}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isMulti
      placeholder=""
    />
  );
}
