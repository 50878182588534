import { Box, IconButton, Menu, MenuItem, styled } from "@mui/material";

export const LanguageMenuContainer = styled(Box)(() => ({}));

export const LanguageMenuIconButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  borderRadius: "25px",
  background: "rgba(237, 28, 36, 0.1)",

  "& img": {
    width: "100%",
  },
}));

export const UserNameIconButton = styled("div")(() => ({
  borderRadius: "24px",
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "32px",
  color: "white",
  gap: "4px",
  padding: "0 8px",
  display: "flex",
  alignItems: "center",
  background: "#ed1c24",
}));

export const LanguageMenuMenu = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(0),
  "& .MuiPaper-root": {
    padding: theme.spacing(0),
  },
}));

export const LanguageMenuMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadius,
  fontSize: "14px",
}));

export const LanguageMenuMenuItemWithSeparator = styled(LanguageMenuMenuItem)(
  ({ theme }) => ({
    position: "relative",
    marginTop: theme.spacing(0),
  })
);

export const LanguageMenuInfo = styled("li")(({ theme }) => ({
  cursor: "pointer",
  padding: theme.spacing(0, 1.5),
  marginBottom: theme.spacing(0),
}));
