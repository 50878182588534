import { Grid } from "@mui/material";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import TimePickerRedux from "components/organisms/TimePickerRedux";
import { useEffect } from "react";
import { Field, change } from "redux-form";
import { useAppDispatch } from "redux/hooks";
import { days } from ".";
import { useTranslation } from "react-i18next";

const AddWorkingHourFields = ({ fields }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initialWorkingHours = Array(7)
      .fill()
      .map((_, index) => ({
        [days[index]]: index < 5,
        is24Hours: false,
        isHoliday: index > 4,
        startTime: { date: new Date(new Date().setHours(8, 0, 0, 0)) },
        endTime: { date: new Date(new Date().setHours(20, 0, 0, 0)) },
      }));
    dispatch(change("AddBranchForm", "workingHours", initialWorkingHours));
  }, [dispatch]);

  const handleIs24HoursChange = (index, isChecked) => {
    const startTime = isChecked
      ? { date: new Date(new Date().setHours(0, 0, 0, 0)) }
      : { date: new Date(new Date().setHours(8, 0, 0, 0)) };
    const endTime = isChecked
      ? { date: new Date(new Date().setHours(24, 0, 0, 0)) }
      : { date: new Date(new Date().setHours(20, 0, 0, 0)) };

    dispatch(
      change("AddBranchForm", `workingHours[${index}].startTime`, startTime)
    );
    dispatch(
      change("AddBranchForm", `workingHours[${index}].endTime`, endTime)
    );
  };

  const handleCheckboxChange = (index, isDayChecked) => {
    dispatch(change("AddBranchForm", `workingHours[${index}].isHoliday`, !isDayChecked));
  };

  return (
    <>
      {fields.length > 0 && (
        <div className="trip-box">
          <h4 style={{ marginBottom: 0 }}>{t("working-hours")}</h4>
          {fields.map((member, index) => (
            <Grid container alignItems={"center"} spacing={2} key={index}>
              <Grid item md={2}>
                <Field
                  name={`${member}.${days[index]}`}
                  component={CheckboxRedux}
                  label={`${t(days[index]).toUpperCase()}`}
                  onChange={(event, newValue) => handleCheckboxChange(index, newValue)}
                />
              </Grid>
              <Grid item md={2}>
                <Field
                  name={`${member}.is24Hours`}
                  component={CheckboxRedux}
                  label={`24 ${t('hours')}`}
                  onChange={(event, newValue) =>
                    handleIs24HoursChange(index, newValue)
                  }
                />
              </Grid>

              <Grid item md={2}>
                <Field
                  name={`${member}.isHoliday`}
                  component={CheckboxRedux}
                  label={t(`holiday`)}
                  onChange={(event, newValue) => {
                    dispatch(change("AddBranchForm", `workingHours[${index}].${days[index]}`, !newValue));
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <Field
                  name={`${member}.startTime`}
                  component={TimePickerRedux}
                  label={t(`start-time`)}
                />
              </Grid>
              <Grid item md={3}>
                <Field
                  name={`${member}.endTime`}
                  component={TimePickerRedux}
                  label={t(`end-time`)}
                />
              </Grid>
            </Grid>
          ))}
        </div>
      )}
    </>
  );
};

export default AddWorkingHourFields;
