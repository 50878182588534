import { reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";

import {
  branchSpecificFields,
  costSpecificFields,
  featureSpecificFields,
} from ".";
import UpdateCarForm from "../UpdateCarForm";
import { useParams } from "react-router-dom";
import useEffectOnce from "hooks/useEffectOnce";
import { E_CAR_FUEL_TYPE, E_CAR_TRANSMISSION_TYPE } from "enum";
import { useTranslation } from "react-i18next";
const form = "AddCarForm";
function AddCarForm({ handleSubmit, initialize }: any) {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  useEffectOnce(() => {
    initialize({
      currentStatus: true,
      isAirConditioned: true,
      availableNow: true,
      defaultAvailability: true,
      transmission: E_CAR_TRANSMISSION_TYPE.AUTO,
      fuelType: E_CAR_FUEL_TYPE.PETROL,
      includes: [{ label: "GPS", value: "GPS" }],
      tags: [{ value: "Budget Friendly", label: "Budget Friendly" }],
      numberOfCars: 1,
      manufacturingYear: 2023,
      numberOfSeats: 5,
      numberOfBags: 2,
      numberOfDoors: 4,
      isUnlimitedKM: true,
      dailyRate: 200,
      weeklyRate: 1200,
      monthlyRate: 5000,
      extraKMCost: 0,
      freeKMPerDay: 300,
    });
  });

  return (
    <div>
      {id && <UpdateCarForm id={id} initialize={initialize} />}
      <form onSubmit={handleSubmit}>
        <h3> {t("branch-details")}</h3>
        <ReduxFormFields fields={branchSpecificFields()} />
        <br />
        <h3>{t("feature-details")}</h3>
        <ReduxFormFields fields={featureSpecificFields()} />
        <br />
        <h3>{t("cost-details")}</h3>
        <ReduxFormFields fields={costSpecificFields()} />
        <br />
        <Button variant="contained" type="submit">
          {t("submit")}
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({
  form: form,
})(AddCarForm);
