import ListItemText from "@mui/material/ListItemText";
import { NavigationItemNestedType } from "../types";
import { listItemPrimaryTypographyProps } from "../../../constants/listItemProps";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import { NavigationItemSimple } from "../navigation-item-simple/NavigationItemSimple";
import { NavigationListItemButton, NavigationListItemIcon } from "../styled";
import { Stack, Typography } from "@mui/material";
import { useNavigation } from "../../../hooks/use-navigation/useNavigation";
import { useCallback, useMemo } from "react";
import { ExpandLess } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

interface Props {
  item: NavigationItemNestedType;
}

export const NavigationItemNested = ({ item }: Props) => {
  const { getIsOpen, toggleNavigationId } = useNavigation();
  const location = useLocation();

  // Check if any nested path matches the current URL
  const isAnyChildActive = useMemo(() => {
    return item.items.some((nestedItem) =>
      location.pathname.startsWith(nestedItem.path)
    );
  }, [location.pathname, item.items]);

  // Combine manual toggle and URL-based active check
  const isOpen = getIsOpen(item.label) || isAnyChildActive;

  const handleToggleOpen = useCallback(() => {
    toggleNavigationId(item.label);
  }, [item.label, toggleNavigationId]);

  const nestedItems = item.items.map((item) => {
    return <NavigationItemSimple key={item.path} item={item} nested />;
  });

  return (
    <>
      <NavigationListItemButton
        onClick={handleToggleOpen}
        disabled={item.disabled}
      >
        <NavigationListItemIcon>{item.icon({})}</NavigationListItemIcon>
        <Stack flex={1}>
          <ListItemText
            primary={item.label}
            primaryTypographyProps={listItemPrimaryTypographyProps}
            sx={{ margin: 0 }}
          />
          {item?.description ? (
            <Typography variant={"caption"}>{item.description}</Typography>
          ) : null}
        </Stack>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </NavigationListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ marginLeft: 2 }}>
          {nestedItems}
        </List>
      </Collapse>
    </>
  );
};
