import DateRangePickerRedux from "components/molecules/DateRangePickerRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import TimePickerRedux from "components/organisms/TimePickerRedux";
import { date, dateRange } from "utils/validate.util";

export { default } from "./UpdateBookingDateTimeForm";

export const fields: ReduxFormField[] = [
  {
    name: "dates",
    label: "Date",
    validate: [dateRange],
    component: DateRangePickerRedux,
    cellProps: { md: 6, lg: 6 },
    DateRangePickerProps: {
      onChange: () => {},
      minDate: new Date(),
      InputFieldProps: {},
      value: { date: ["", ""], error: ["", ""] },
    },
  },
  {
    name: "pickupTime",
    label: "pickup-time",
    validate: [date],
    component: TimePickerRedux,
    cellProps: { md: 3, lg: 3 },
  },
  {
    name: "dropOffTime",
    label: "drop-off-time",
    validate: [date],
    component: TimePickerRedux,
    cellProps: { md: 3, lg: 3 },
  },
];
