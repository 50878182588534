import { useEffect } from "react";
import { Container, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./FranchiseDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import FranchiseService from "services/franchise.service";
import { franchiseActions } from "redux/slices/franchise";
import DateService from "utils/date.util";
import { useTranslation } from "react-i18next";

export default function FranchiseDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const franchise = useAppSelector((state) => state.franchise.franchise);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    FranchiseService.getFranchise(id || "");
    return () => {
      dispatch(franchiseActions.setFranchise(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/franchises" title={t("back-to-franchises")} />
      <PageHeader title={t("franchise-details")} />
      {loading && <CircleLoader />}
      {id && !franchise ? (
        <p>{t("franchise-not-found")}</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={t(franchise?.currentStatus)} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-franchise/${franchise._id}`)}
            >
              {t("edit")}
            </Button>
          </Stack>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t("franchise-ID")}</label>
            <p>{franchise?.docNumber}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t("franchise-code")}</label>
            <p>{franchise?.franchiseCode}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t("name")}</label>
            <p>{t(franchise?.name)}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t('phone')}</label>
            <p>
              +{franchise?.phoneNumber?.dialingCode}
              {franchise?.phoneNumber.number}
            </p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t("email")}</label>
            <p>{franchise?.email}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t("main-contact-person")}</label>
            <p>{franchise?.mainContactPerson}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t("company")}</label>
            <br />
            <Link
              to={`/company-details/${franchise?.companyId?._id}`}
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {franchise?.companyId?.name}
            </Link>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t("number-Of-employees")}</label>
            <p>{franchise?.numberOfEmployees}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t("address")}</label>
            <p>{franchise?.address?.formattedAddress}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">{t("date-of-establishment")}</label>
            <p>{DateService.getDateString(franchise?.dateOfEstablishment)}</p>
          </div>
        </div>
      )}
    </Container>
  );
}
