import { useEffect } from "react";
import { Container, Grid, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./CarPriceDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import CarPriceService from "services/carPrice.service";
import { carPriceActions } from "redux/slices/carPrice";
import { useTranslation } from "react-i18next";

export default function CarPriceDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carPrice = useAppSelector((state) => state.carPrice.carPrice);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    CarPriceService.getCarPrice(id || "");
    return () => {
      dispatch(carPriceActions.setCarPrice(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/car-prices" title={t("back-to-car-prices")} />
      <PageHeader title={t("car-price-details")} />
      {loading && <CircleLoader />}
      {!loading && !carPrice ? (
        <p>{t("car-price-not-found")}</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={t(carPrice?.currentStatus)} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-car-price/${carPrice._id}`)}
            >
              Edit
            </Button>
          </Stack>
          <Grid container>
            <Grid item md={6} lg={3}>
              <div className={styles.fieldBox}>
                <label htmlFor=""> {t("ID")}</label>
                <p>{carPrice?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("name")}</label>
                <p>{carPrice?.name}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("supplier")}</label>
                <br />
                <Link
                  to={`/supplier-details/${carPrice?.supplierId?._id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {carPrice?.supplierId?.name}
                </Link>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("daily-rate")}:</label>
                <p>{carPrice?.dailyRate?.toFixed(2)} SAR</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("extra-KM-cost")}:</label>
                <p>{carPrice?.extraKMCost?.toFixed(2)} SAR</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("unlimited-KM")}:</label>
                <p>{carPrice?.isUnlimitedKM ? t("yes") : t("no")}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("free-KM-per-day")}:</label>
                <p>{carPrice?.freeKMPerDay}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}
