import {
  Box,
  Drawer,
  IconButton,
  Stack,
  styled,
  Toolbar,
  useTheme,
} from "@mui/material";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactNode } from "react";
import { Navigation } from "./components/navigation/Navigation";

import { ToolbarElements } from "./components/toolbar-elements/ToolbarElements";
import { useNavigation } from "./components/navigation/hooks/use-navigation/useNavigation";
import Logo from "assets/logo.png";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import useEffectOnce from "hooks/useEffectOnce";
import AuthService from "services/auth.service";
import { useTranslation } from "react-i18next";

const drawerWidth = 280;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 1, 0, 4),
  // necessary for content to be below app bar
  justifyContent: "space-between",
}));

interface Props {
  children: ReactNode;
}

export function SidebarLayout({ children }: Props) {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const { isSidebarOpen, toggleSidebar } = useNavigation();

  const location = useLocation();
  const { pathname } = location;
  const user = useAppSelector((state) => state.auth.user);

  useEffectOnce(() => {
    if (!user) return;

    AuthService.splash();
  });

  const Main = styled("main", {
    shouldForwardProp: (prop) => prop !== "open",
  })<{
    open?: boolean;
  }>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(4),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    borderBottom: "1px solid #e0e0e0",
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxShadow: "none",
      borderBottom: "1px solid #e0e0e0",
    }),
  }));

  if (
    !user ||
    pathname === "/" ||
    pathname === "/password-otp" ||
    pathname === "/reset-password" ||
    pathname === "/forgot-password"
  )
    return <>{children}</>;

  return (
    <Box>
      <AppBar position="relative" open={isSidebarOpen} color="transparent">
        <Toolbar sx={{ minHeight: "72px !important" }}>
          <Stack
            justifyContent={isSidebarOpen ? "flex-end" : "space-between"}
            direction={"row"}
            flex={1}
            alignItems={"center"}
          >
            <Stack
              justifyContent={"flex-start"}
              direction={"row"}
              flex={1}
              alignItems={"center"}
              sx={{ mr: 2, ...(isSidebarOpen && { display: "none" }) }}
            >
              <IconButton
                color="inherit"
                aria-label="open navigation"
                onClick={toggleSidebar}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <img
                src={Logo}
                alt="logo"
                loading="lazy"
                style={{ width: "100%", maxWidth: "140px" }}
              />
            </Stack>
            <ToolbarElements />
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        elevation={0}
        variant="persistent"
        anchor={"left"}
        open={isSidebarOpen}
      >
        <DrawerHeader>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <img
              src={Logo}
              alt="logo"
              loading="lazy"
              style={{ width: "100%", maxWidth: "140px" }}
            />
          </Stack>
          <IconButton onClick={toggleSidebar}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        {/* ------- Navigation List ------- */}
        <Navigation />
      </Drawer>
      <Main open={isSidebarOpen}>{children}</Main>
    </Box>
  );
}
