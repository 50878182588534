import InputRedux from "components/molecules/InputRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import {
  email,
  phone,
  required,
  requiredAutoComplete,
  requiredPhone,
} from "utils/validate.util";
import SelectCompany from "components/organisms/SelectCompany";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import SelectFranchise from "components/organisms/SelectFranchise";
import CheckboxRedux from "components/molecules/CheckboxRedux";

export { default } from "./AddEmployeeForm";

export const fields: ReduxFormField[] = [
  {
    name: "currentStatus",
    label: "is-active",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  {
    name: "firstName",
    label: "first-name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "lastName",
    label: "last-name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "phone",
    label: "phone",
    validate: [requiredPhone, phone],
    component: PhoneInputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "email",
    label: "email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
    InputProps: { type: "email" },
  },
  {
    name: "designation",
    label: "designation",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "employeeNumber",
    label: "employee-number",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "companyId",
    label: "company",
    validate: [required],
    component: SelectCompany,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "franchiseId",
    label: "franchise",
    validate: [required],
    component: SelectFranchise,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "address",
    label: "address",
    validate: [required, requiredAutoComplete],
    component: AutoCompleteRedux,
    cellProps: { md: 12, lg: 12 },
  },
  {
    name: "description",
    label: "description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 12, lg: 12 },
    InputProps: { multiline: true, rows: 5 },
  },
];
