import InputRedux from "components/molecules/InputRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import {
  email,
  phone,
  required,
  requiredAutoComplete,
  requiredPhone,
} from "utils/validate.util";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import SelectSupplier from "components/organisms/SelectSupplier";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import TimePickerRedux from "components/organisms/TimePickerRedux";

export { default } from "./AddBranchForm";

export const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const fields: ReduxFormField[] = [
  {
    name: "currentStatus",
    label: "is-active",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  {
    name: "name",
    label: "name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 5, lg: 5 },
  },
  {
    name: "branchCode",
    label: "branch-code",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 5, lg: 5 },
  },
  {
    name: "supplierId",
    label: "supplier",
    validate: [required],
    component: SelectSupplier,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "address",
    label: "address",
    validate: [required, requiredAutoComplete],
    component: AutoCompleteRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "phone",
    label: "phone",
    validate: [requiredPhone, phone],
    component: PhoneInputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "email",
    label: "email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
    InputProps: { type: "email" },
  },
  {
    name: "description",
    label: "description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 12, lg: 12 },
    InputProps: { multiline: true, rows: 5 },
  },
];

export const dayFields: ReduxFormField[] = [
  {
    name: "monday",
    label: "monday",
    validate: [required],
    component: CheckboxRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "is24Hours",
    label: "24 Hours",
    validate: [required],
    component: CheckboxRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "holiday",
    label: "holiday",
    validate: [required],
    component: CheckboxRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "startTime",
    label: "start-time",
    validate: [required],
    component: TimePickerRedux,
    cellProps: { md: 3, lg: 3 },
  },
  {
    name: "endTime",
    label: "end-time",
    validate: [required],
    component: TimePickerRedux,
    cellProps: { md: 3, lg: 3 },
  },
];
