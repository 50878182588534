import { config } from "config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BudgetState } from ".";

const default_page_size = config.PAGE_SIZE;
const initialState: BudgetState = {
  budgets: [],
  budget: null,
  reasons: [],
  loading: true,
  current_filters: {},
  filters: { page: 1, pageSize: default_page_size },
  refresh: 0,
  refreshLoader: false,
  totalCount: 0,
};

export const budgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {
    // clear: (state) => {
    //   state.carOptions = [];
    // },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addBudget: (state, action) => {
      state.budgets.unshift(action.payload);
    },
    updateBudget: (state, action) => {
      const { id, car } = action.payload;
      state.budgets.every(({ _id }: any, i: number) => {
        if (id === _id) {
          state.budgets[i] = car;
          return false;
        }
        return true;
      });
    },
    deleteBudgetById: (state, action) => {
      const id = action.payload;
      state.budgets.every(({ _id }: any, i: number) => {
        if (id === _id) {
          state.totalCount -= 1;
          state.budgets.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setBudget: (state, action) => {
      state.budget = action.payload;
    },
    setBudgets: (state, action: PayloadAction<any>) => {
      const { budgets } = action.payload;
      state.budgets = budgets;
    },
    setReasons: (state, action) => {
      state.reasons = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<any>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
    },
    setPage: (state, action: PayloadAction<any>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const budgetReducer = budgetSlice.reducer;
export const budgetActions = budgetSlice.actions;
export default budgetReducer;
