import List from "@mui/material/List";
import { NavigationItem } from "./components/navigation-item/NavigationItem";
import { NavigationItemType } from "./components/navigation-item/types";
import {
  AirportShuttleOutlined,
  FactCheckOutlined,
  AssignmentIndOutlined,
  AttributionOutlined,
  GarageOutlined,
  MinorCrashOutlined,
  HealthAndSafetyOutlined,
  CurrencyExchange,
} from "@mui/icons-material";
import UpcomingOutlinedIcon from "@mui/icons-material/UpcomingOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { IoCarSportOutline } from "react-icons/io5";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function Navigation() {
  const { t } = useTranslation();
  const navigationItems: NavigationItemType[] = useMemo(
    () => [
      {
        path: "/dashboard",
        label: `${t("dashboard")}`,
        icon: (props: any) => <DashboardOutlinedIcon {...props} />,
      },
      {
        label: `${t("bookings")}`,
        icon: (props: any) => <UpcomingOutlinedIcon {...props} />,
        description: `${t("bookings-history")}`,
        items: [
          {
            path: "/search-fleet",
            label: `${t("add-booking")}`,
            icon: (props: any) => <AddBoxOutlinedIcon {...props} />,
          },
          {
            path: "/upcoming-bookings",
            label: `${t("upcoming")}`,
            icon: (props: any) => <UpcomingOutlinedIcon {...props} />,
          },
          {
            path: "/ongoing-bookings",
            label: `${t("on-going")}`,
            icon: (props: any) => <AirportShuttleOutlined {...props} />,
          },
          {
            path: "/past-bookings",
            label: `${t("past")}`,
            icon: (props: any) => <FactCheckOutlined {...props} />,
          },
          {
            path: "/bookings",
            label: `${t("all-orders")}`,
            icon: (props: any) => <IoCarSportOutline {...props} />,
          },
        ],
      },

      {
        label: `${t("users")}`,
        icon: (props: any) => <AssignmentIndOutlined {...props} />,
        description: `${t("users-management")}`,
        items: [
          {
            path: "/users",
            label: `${t("users")}`,
          },
          {
            path: "/add-user",
            label: `${t("add-user")}`,
          },
        ],
      },

      // {
      //   header: "B2B Management",
      // },
      {
        label: `${t("B2B-Management")}`,
        icon: (props: any) => <EmojiTransportationOutlinedIcon {...props} />,
        description: `${t("manage-companies")}`,
        items: [
          {
            path: "/companies",
            label: `${t("companies")}`,
          },
          {
            path: "/franchises",
            label: `${t("franchises")}`,
          },
          {
            path: "/employees",
            label: `${t("employees")}`,
          },
        ],
      },

      {
        // path: "/suppliers",
        label: `${t("Non-API-Providers")}`,
        icon: (props: any) => <AttributionOutlined {...props} />,
        description: `${t("manage-suppliers")}`,
        items: [
          {
            path: "/suppliers",
            label: `${t("suppliers")}`,
          },
          {
            path: "/branches",
            label: `${t("branch")}`,
          },
          {
            path: "/cars",
            label: `${t("cars")}`,
          },
          // {
          //   path: "/car-prices",
          //   label: "Car Pricing",
          // },
          {
            path: "/car-extras",
            label: `${t("car-extras")}`,
          },
          {
            path: "/car-categories",
            label: `${t("car-categories")}`,
          },
          {
            path: "/car-models",
            label: `${t("car-models")}`,
          },
        ],
      },

      {
        // path: "/car-specs",
        label: `${t("yelo-specifications")}`,
        icon: (props: any) => <MinorCrashOutlined {...props} />,
        description: `${t("manage-yelo-specs")}`,
        items: [
          {
            path: "/car-specs",
            label: `${t("car-specs")}`,
          },
          {
            path: "/add-car-specs",
            label: `${t("add-car-specs")}`,
          },
        ],
      },
      {
        label: `${t("insurance-packages")}`,
        icon: (props: any) => <HealthAndSafetyOutlined {...props} />,
        description: `${t("insurance-plans")}`,
        items: [
          {
            path: "/insurance",
            label: `${t("insurance")}`,
          },
          {
            path: "/add-insurance",
            label: `${t("add-insurance")}`,
          },
        ],
      },
      {
        label: `${t("promotions")}`,
        icon: (props: any) => <GarageOutlined {...props} />,
        description: `${t("coupon-campaigns")}`,
        items: [
          {
            path: "/promotions",
            label: `${t("promotions")}`,
          },
          {
            path: "/loyalty-tier",
            label: `${t("loyalty-tier")}`,
          },
        ],
      },
      {
        // path: "/price-engine",
        label: `${t("price-engine")}`,
        icon: (props: any) => <CalculateOutlinedIcon {...props} />,
        description: `${t("pricing-plans")}`,
        items: [
          {
            path: "/price-engine",
            label: `${t("price-engine")}`,
          },
          {
            path: "/add-price",
            label: `${t("add-price")}`,
          },
        ],
      },
      {
        path: "/tickets",
        label: `${t("support-tickets")}`,
        icon: (props: any) => <SupportAgentOutlinedIcon {...props} />,
      },
      {
        path: "/budgets",
        label: `${t("budget-requests")}`,
        icon: (props: any) => <CurrencyExchange {...props} />,
      },
    ],
    []
  );

  const navigationItemsList = navigationItems.map((item) => {
    return <NavigationItem key={Object.values(item).toString()} item={item} />;
  });

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, padding: 2 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {navigationItemsList}
    </List>
  );
}
