import React from "react";
import UpdateBookingExtrasForm from "./UpdateBookingExtrasForm";

const UpdateBookingExtras = () => {
  const handleSubmit = (values: any) => {};
  return (
    <UpdateBookingExtrasForm
    // onSubmit={handleSubmit}
    />
  );
};

export default UpdateBookingExtras;
