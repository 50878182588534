import {
  Box,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import CircleLoader from "components/atoms/CircleLoader";
import GoBack from "components/atoms/GoBack";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import BagIcon from "assets/icons/bag-icon.svg";
import SeatIcon from "assets/icons/seat-icon.svg";
import TransmissionIcon from "assets/icons/transmission-icon.svg";
import DoorsIcon from "assets/icons/door-icon.svg";
import KmIcon from "assets/icons/km-icon.svg";
import FuelIcon from "assets/icons/fuel-icon.svg";
import { change, reduxForm } from "redux-form";
import BookingService from "services/booking.service";
import { bookingActions } from "redux/slices/booking";
import { useParams } from "react-router-dom";
import UpdateBookingUser from "../UpdateBookingUser";
import UpdateBookingDateTime from "../UpdateBookingDateTime";
import UpdateBookingExtras from "../UpdateBookingExtras";
import UpdateBookingInsurance from "../UpdateBookingInsurance";
import AddBookingService from "services/addBooking.service";
import UpdateBookingPricing from "../UpdateBookingPricing";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const UpdateBookingForm = ({ handleSubmit, initialize }: any) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const booking = useAppSelector((state) => state.booking?.booking);
  const branches = useAppSelector((state) => state.branch?.branches);
  const extras = useAppSelector((state) => state.addBooking.extras);
  const loading = useAppSelector((state) => state.booking?.loading);
  const submitLoading = useAppSelector((state) => state.formLoader.loading);

  const pickup = useAppSelector(
    (state) => state.form?.["UpdateBookingForm"]?.values?.pickup
  );
  const filteredBranch = branches.find((doc) => doc._id === pickup);

  const dates = useAppSelector(
    (state) => state.form?.["UpdateBookingForm"]?.values?.dates
  );

  // const pickupTime = useAppSelector(
  //   (state) => state.form?.["UpdateBookingForm"]?.values?.pickupTime
  // );
  // const dropOffTime = useAppSelector(
  //   (state) => state.form?.["UpdateBookingForm"]?.values?.dropOffTime
  // );

  useEffect(() => {
    if (booking) {
      // let initialValues: any;
      let initialUser: any = {};
      // let selectedInsuranceId: any;

      if (booking?.userId) initialUser.userId = booking?.userId;
      initialUser.firstName = booking.mainDriver?.firstName;
      initialUser.lastName = booking?.mainDriver?.lastName;
      initialUser.phone = {
        value:
          booking?.mainDriver?.phoneNumber?.dialingCode +
          booking?.mainDriver?.phoneNumber.number,
      };
      initialUser.email = booking?.mainDriver?.email;

      initialize({
        pickup: booking?.pickupBranchDetail?.id,
        dates: {
          error: [null, null],
          date: [
            booking?.pickupDate.replace("Z", ""),
            booking?.dropOffDate.replace("Z", ""),
          ],
        },
        pickupTime: {
          error: null,
          date: booking?.pickupDate.replace("Z", ""),
        },
        dropOffTime: {
          error: null,
          date: booking?.dropOffDate.replace("Z", ""),
        },
        isPaid: booking?.paymentStatus === "paid",
        adminTotalPrice: (
          booking?.paymentDetail?.bookingNetAmount * 1.15
        )?.toFixed(2),
        // ...(selectedInsuranceId && { insurance: selectedInsuranceId }),
        ...(initialUser && { ...initialUser }),
        // ...(initialValues && { ...initialValues }),
      });
    }
  }, [booking]);

  useEffect(() => {
    BookingService.getBooking(`${id}`);

    return () => {
      dispatch(bookingActions.setBooking(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (extras?.extras?.length > 0) {
      let initialValues = extras.extras.reduce((acc: any, extra: any) => {
        const isSelected = booking?.extras?.some(
          (bExtra: any) => bExtra.id === extra.id
        );
        acc[extra.id] = isSelected;
        return acc;
      }, {});
      Object.entries(initialValues).forEach(([key, value]: [string, any]) => {
        dispatch(change("UpdateBookingForm", key, value));
      });
    }
    if (extras?.insurances?.length > 0) {
      let selectedInsuranceId = booking?.insurances?.[0]?.id;
      if (selectedInsuranceId) {
        // initialize({ insurance: selectedInsuranceId });
        dispatch(change("UpdateBookingForm", "insurance", selectedInsuranceId));
      }
    }
  }, [booking?.extras, booking?.insurances, dispatch, extras]);

  useEffect(() => {
    if (
      booking &&
      pickup! &&
      dates?.date.length > 0 &&
      dates?.date[0] !== "" &&
      dates?.date[1] !== ""
    ) {
      let pickupBranchCode = booking?.pickupBranchDetail?.branchCode;
      let dropOffBranchCode = booking?.dropOffBranchDetail?.branchCode;
      if (pickup && pickup! !== booking?.pickupBranchDetail?.id) {
        pickupBranchCode = filteredBranch?.branchCode;
        dropOffBranchCode = filteredBranch?.branchCode;
      }
      let payload = {
        pickupBranchCode,
        dropOffBranchCode,
        pickupDate:
          dates?.date[0] !== "" &&
          (typeof dates?.date[0] === "object"
            ? format(dates?.date[0], "yyyy-MM-dd'T'HH:mm:ss'.000Z'")
            : dates?.date[0]),
        dropOffDate:
          dates?.date[1] !== "" &&
          (typeof dates?.date[1] === "object"
            ? format(dates?.date[1], "yyyy-MM-dd'T'HH:mm:ss'.000Z'")
            : dates?.date[1]),
        acrissCode: booking?.acrissCode ?? "EDNV",
        dailyRate: booking?.paymentDetail?.comfyDailyRate,
        carId: booking?.carId,
        supplierId: booking?.supplierId,
        manufacturingYear: booking?.carDetail?.manufacturingYear,
      };

      // if (pickup! !== booking?.pickupBranchDetail?.id) {
      AddBookingService.getExtras(payload);
      // }
    }
  }, [booking, pickup, dates]);

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="xl">
        <GoBack
          path={`/booking-details/${id}`}
          title={t("back-to-booking-detail")}
        />
        <PageHeader title={t("update-booking")} />
        {(loading || submitLoading) && <CircleLoader />}
        {!loading && !booking ? (
          <p>Booking not found</p>
        ) : (
          <Grid container columnSpacing={2}>
            <Grid item md={8}>
              <div className="form">
                <Box
                  display={"flex"}
                  gap={4}
                  pb={4}
                  borderBottom={"1px solid #cdcdcd"}
                >
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={booking?.carDetail?.image}
                      alt="Car"
                      style={{
                        maxWidth: "240px",
                        maxHeight: "180px",
                        minWidth: "240px",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                    <Box
                      display={"flex"}
                      gap={0.5}
                      position={"absolute"}
                      top={0}
                    >
                      {booking?.carDetail?.tags?.map(
                        (tag: any, index: number) => (
                          <Box
                            sx={{
                              padding: "2px 6px",
                              borderRadius: "4px",
                              backgroundColor: "#d1ecf1",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "#31708f",
                                textTransform: "capitalize",
                              }}
                              key={index}
                            >
                              {t(tag)}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                  <CardContent
                    sx={{
                      width: "100%",
                      padding: 0,
                      paddingBottom: "0 !important",
                    }}
                  >
                    <Box display="flex" flexDirection="column" height="100%">
                      <Stack direction={"row"} gap={1} alignItems={"center"}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          textTransform={"capitalize"}
                        >
                          {t(booking?.carDetail?.name)} -{" "}
                          {booking?.carDetail?.manufacturingYear}
                        </Typography>
                        <Box
                          border={"1px solid #cdcdcd"}
                          borderRadius={"4px"}
                          padding={"2px 8px"}
                          overflow={"hidden"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <img
                            src={booking?.supplierDetail?.businessLogo}
                            alt="Supplier Logo"
                            style={{
                              width: "42px",
                            }}
                          />
                        </Box>
                      </Stack>
                      <Typography variant="body2" color="textSecondary">
                        {t("or-similar")}
                      </Typography>

                      {/* Features Section */}
                      <Grid container spacing={1} my={1}>
                        <Grid item xs={4}>
                          <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <img src={BagIcon} alt="Bags" />
                            <Typography
                              textTransform={"capitalize"}
                              variant="body2"
                            >
                              {booking?.carDetail?.numberOfBags} {t("bags")}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <img src={SeatIcon} alt="Seats" />
                            <Typography
                              textTransform={"capitalize"}
                              variant="body2"
                            >
                              {booking?.carDetail?.numberOfSeats} {t("seats")}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <img src={TransmissionIcon} alt="Transmission" />
                            <Typography
                              textTransform={"capitalize"}
                              variant="body2"
                            >
                              {t(booking?.carDetail?.transmission)}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <img src={DoorsIcon} alt="Doors" />
                            <Typography
                              textTransform={"capitalize"}
                              variant="body2"
                            >
                              {booking?.carDetail?.numberOfDoors} {t("doors")}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <img src={FuelIcon} alt="Fuel" />
                            <Typography
                              textTransform={"capitalize"}
                              variant="body2"
                            >
                              {booking?.carDetail?.fuelType}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <img src={KmIcon} alt="Free Km" />
                            <Typography
                              textTransform={"capitalize"}
                              variant="body2"
                            >
                              {booking?.carDetail?.isUnlimitedKM
                                ? t("unlimited-KM")
                                : `free ${booking?.carDetail?.freeKMPerDay}km`}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Box>

                <Box py={1.5} borderBottom={"1px solid #cdcdcd"}>
                  <UpdateBookingDateTime />
                </Box>

                <Box py={1.5} borderBottom={"1px solid #cdcdcd"}>
                  <Grid container columnSpacing={2}>
                    <UpdateBookingExtras />
                    <UpdateBookingInsurance />
                  </Grid>
                </Box>

                <Box py={1.5}>
                  <UpdateBookingUser />
                </Box>
              </div>
            </Grid>
            <Grid item md={4}>
              <UpdateBookingPricing />
            </Grid>
          </Grid>
        )}
      </Container>
    </form>
  );
};
export default reduxForm({ form: "UpdateBookingForm" })(UpdateBookingForm);
