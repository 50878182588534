import Empty from "assets/empty.svg";
import { TableBody } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../Tables";
import Button from "components/atoms/Button";
import { useTranslation } from "react-i18next";

export default function NoResult({ message }: any) {
	const { t } = useTranslation();
	return (
		<TableBody>
			<StyledTableRow>
				<StyledTableCell align="center">
					<div
						style={{
							minHeight: "50vh",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<h3>{t("sorry")}  :&#40;</h3>
						<p style={{ margin: 0 }}>{message}.</p>
						<br />
						<br />
						<img
							src={Empty}
							loading="lazy"
							alt="No results"
							style={{ width: "250px" }}
						/>
						<br />
						<br />
						<br />
						<Button variant="outlined" onClick={() => { window.location.reload() }}>{t("refresh")}</Button>
					</div>
				</StyledTableCell>
			</StyledTableRow>
		</TableBody>
	);
}
