import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
  Stack,
  Pagination,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { carActions } from "redux/slices/car";
import CarService from "services/car.service";
import Chip from "components/atoms/Chip";
import {
  MdOutlineAirlineSeatReclineNormal,
  MdOutlineLuggage,
} from "react-icons/md";
import { GiCarDoor } from "react-icons/gi";
import {
  TbAutomaticGearbox,
  TbManualGearbox,
  TbSnowflake,
  TbSnowflakeOff,
} from "react-icons/tb";
import CarsFilters from "../Filters/CarsFilters";
import { config } from "config";
import { CarListProps } from ".";
import { useTranslation } from "react-i18next";

export default function CarsList({ showFilters }: CarListProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const cars = useAppSelector((state) => state.car.cars);
  const loading = useAppSelector((state) => state.car.loading);
  const filters = useAppSelector((state) => state.car.filters);
  const refresh = useAppSelector((state) => state.car.refresh);
  const refreshLoader = useAppSelector((state) => state.car.refreshLoader);
  const totalCount = useAppSelector((state) => state.car.totalCount);
  const [loadingToggles, setLoadingToggles] = useState<{
    [key: string]: boolean;
  }>({});
  const totalPages = Math.ceil(
    totalCount / (filters.pageSize ?? config.PAGE_SIZE ?? 30)
  );

  useEffect(() => {
    return () => {
      dispatch(carActions.resetPage());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = showFilters ? filters : null;
    CarService.getCars(data);
  }, [filters, refresh, showFilters]);

  const handleAvailabilityToggle = async (
    carId: string,
    field: "availableNow" | "defaultAvailability",
    value: boolean
  ) => {
    try {
      setLoadingToggles((prev) => ({ ...prev, [`${carId}-${field}`]: true }));
      await CarService.updateCarAvailability(carId, { [field]: value });
    } catch (error) {
      console.error("Error updating car availability:", error);
    } finally {
      setLoadingToggles((prev) => ({ ...prev, [`${carId}-${field}`]: false }));
    }
  };

  return (
    <>
      <TableContainer>
        {showFilters && <CarsFilters />}
        <Box
          p={3}
          pt={0}
          bgcolor={"#ffffff"}
          borderRadius={"4px"}
          border={"1px solid #d3d3d3"}
        >
          <Table
            aria-label="customized table"
            sx={{
              minWidth: "100%",
              borderSpacing: "0 10px",
              borderBottomWidth: "0px",
              borderCollapse: "separate",
            }}
          >
            <TableLoadingWrapper
              coloumns={7}
              loading={loading}
              length={cars.length < 1 && loading ? 0 : cars.length}
              message="No one has connected with our rental services yet"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("ID")}</StyledTableCell>
                  <StyledTableCell>{t("car")}</StyledTableCell>
                  <StyledTableCell>{t("supplier")}</StyledTableCell>
                  <StyledTableCell>{t("daily-rate")}</StyledTableCell>
                  <StyledTableCell>{t("specs")}</StyledTableCell>
                  <StyledTableCell>{t("availability")}</StyledTableCell>
                  <StyledTableCell>{t("status")}</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cars.map((car, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{car?.docNumber}</StyledTableCell>
                    <StyledTableCell>
                      {car?.modelId?.manufacturer} <br />
                      {car?.modelId?.name} - {car?.manufacturingYear}
                    </StyledTableCell>
                    <StyledTableCell>
                      {car?.supplierId?.name}
                      <br />
                      {car?.supplierId?.email}
                      <br />
                      {car?.supplierId?.phoneNumber?.dialingCode}
                      {car?.supplierId?.phoneNumber?.number}
                    </StyledTableCell>
                    <StyledTableCell>
                      {car?.dailyRate?.toFixed(2)} SAR
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction={"row"} alignItems={"center"} mb={1}>
                        <MdOutlineLuggage size={"18px"} />
                        {car?.numberOfBags} &nbsp;&nbsp;
                        <MdOutlineAirlineSeatReclineNormal size={"18px"} />
                        {car?.numberOfSeats}&nbsp;&nbsp;&nbsp;
                        <GiCarDoor size={"18px"} />
                        {car?.numberOfDoors}
                      </Stack>
                      {car?.transmission === "AUTO" ? (
                        <TbAutomaticGearbox size={"18px"} />
                      ) : (
                        <TbManualGearbox size={"18px"} />
                      )}
                      &nbsp;&nbsp;
                      {car?.isAirConditioned ? (
                        <TbSnowflake size={"18px"} />
                      ) : (
                        <TbSnowflakeOff size={"18px"} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack spacing={1} sx={{ minWidth: "200px" }}>
                        <FormControlLabel
                          control={
                            <Box
                              sx={{
                                position: "relative",
                                display: "inline-flex",
                              }}
                            >
                              <Switch
                                checked={car?.availableNow ?? false}
                                onChange={(e) =>
                                  handleAvailabilityToggle(
                                    car._id,
                                    "availableNow",
                                    e.target.checked
                                  )
                                }
                                disabled={
                                  loadingToggles[`${car._id}-availableNow`]
                                }
                              />
                              {loadingToggles[`${car._id}-availableNow`] && (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                  }}
                                />
                              )}
                            </Box>
                          }
                          label={t("available-now")}
                          sx={{
                            margin: 0,
                            "& .MuiFormControlLabel-label": {
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Box
                              sx={{
                                position: "relative",
                                display: "inline-flex",
                              }}
                            >
                              <Switch
                                checked={car?.defaultAvailability ?? false}
                                onChange={(e) =>
                                  handleAvailabilityToggle(
                                    car._id,
                                    "defaultAvailability",
                                    e.target.checked
                                  )
                                }
                                disabled={
                                  loadingToggles[
                                    `${car._id}-defaultAvailability`
                                  ]
                                }
                              />
                              {loadingToggles[
                                `${car._id}-defaultAvailability`
                              ] && (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                  }}
                                />
                              )}
                            </Box>
                          }
                          label={t("default-availability")}
                          sx={{
                            margin: 0,
                            "& .MuiFormControlLabel-label": {
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Chip status={car?.currentStatus} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link
                        className="detail-link"
                        to={`/car-details/${car._id}`}
                      >
                        {t("details")}
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TableLoadingWrapper>
          </Table>
          {!refreshLoader && showFilters && (
            <Stack alignItems={"center"}>
              <Pagination
                variant="outlined"
                shape="rounded"
                page={filters.page}
                count={Math.ceil(totalPages ?? filters.pageSize)}
                onChange={(_e, page) => dispatch(carActions.setPage({ page }))}
              />
            </Stack>
          )}
        </Box>
      </TableContainer>
    </>
  );
}
