import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { number, required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import SelectCarModel from "components/organisms/SelectCarModel";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";
import SelectBranch from "components/organisms/SelectBranch";
import SelectSupplier from "components/organisms/SelectSupplier";

export { default } from "./AddCarForm";

const includeOptions = [
  { value: "GPS", label: "gps" },
  { value: "Bluetooth", label: "bluetooth" },
  { value: "Child Seat", label: "child-seat" },
  { value: "Backup Camera", label: "backup-camera" },
  { value: "Heated Seats", label: "heated-seats" },
  { value: "Leather Interior", label: "leather-interior" },
  { value: "All-Wheel Drive", label: "all-wheel-drive" },
  { value: "Parking Sensors", label: "parking-sensors" },
  { value: "Sunroof", label: "sunroof" },
  { value: "USB Charger", label: "usb-charger" },
  { value: "Cruise Control", label: "cruise-control" },
  { value: "Satellite Radio", label: "satellite-radio" },
  { value: "Keyless Entry", label: "keyless-entry" },
  {
    value: "Automatic Emergency Braking",
    label: "automatic-emergency-braking",
  },
  { value: "Lane Departure Warning", label: "lane-departure-warning" },
  { value: "Blind Spot Monitoring", label: "blind-spot-monitoring" },
];

const tagOptions = [
  { value: "Family-Friendly", label: "family-friendly" },
  { value: "Recommended for Families", label: "recommended-for-families" },
  { value: "Best for 4 People", label: "best-for-4-people" },
  { value: "Spacious Interior", label: "spacious-interior" },
  { value: "Comfortable Ride", label: "comfortable-ride" },
  { value: "High Safety Ratings", label: "high-safety-ratings" },
  { value: "Fuel Efficient", label: "fuel-efficient" },
  { value: "Budget Friendly", label: "budget-friendly" },
  { value: "Everyday Commuter", label: "everyday-commuter" },
];

export const branchSpecificFields = (): ReduxFormField[] => {
  return [
    {
      name: "currentStatus",
      label: "is-active",
      validate: [required],
      component: CheckboxRedux,
      cellProps: { md: 12 },
    },

    {
      name: "supplierId",
      label: "supplier",
      validate: [required],
      component: SelectSupplier,
      cellProps: { md: 3 },
    },

    {
      name: "branchId",
      label: "branch",
      validate: [required],
      component: SelectBranch,
      cellProps: { md: 3 },
    },

    {
      name: "modelId",
      label: "car-model",
      validate: [required],
      component: SelectCarModel,
      cellProps: { md: 3 },
    },
    {
      name: "manufacturingYear",
      label: "manufacturing-year",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
    },
    {
      name: "tags",
      label: "tags",
      validate: [required],
      component: ComboBoxRedux,
      ComboBoxProps: {
        multiple: true,
        freeSolo: false,
        options: tagOptions,
      },
      cellProps: { md: 3 },
    },
    {
      name: "includes",
      label: "includes",
      validate: [required],
      component: ComboBoxRedux,
      ComboBoxProps: {
        multiple: true,
        freeSolo: false,
        options: includeOptions,
      },
      cellProps: { md: 3 },
    },

    {
      name: "numberOfCars",
      label: "number-of-cars",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
      InputProps: {
        disabled: false,
      },
    },
  ];
};

export const featureSpecificFields = (): ReduxFormField[] => {
  return [
    {
      name: "isAirConditioned",
      label: "air-conditioned",
      component: CheckboxRedux,
      cellProps: { md: 4 },
      CheckBoxProps: {
        disabled: false,
      },
    },

    {
      name: "availableNow",
      label: "Available Now (Next 24 Hours)",
      component: CheckboxRedux,
      cellProps: { md: 4 },
      CheckBoxProps: {
        disabled: false,
      },
    },
    {
      name: "defaultAvailability",
      label: "Default Availability (Beyond 24 Hours)",
      component: CheckboxRedux,
      cellProps: { md: 4 },
      CheckBoxProps: {
        disabled: false,
      },
    },
    {
      name: "fuelType",
      label: "fuel-type",
      validate: [required],
      component: SelectRedux,
      cellProps: { md: 3 },
      SelectProps: {
        options: [
          { value: "PETROL", label: "petrol" },
          { value: "ELECTRIC", label: "electric" },
          { value: "HYBRID", label: "hybrid" },
          { value: "DIESEL", label: "diesel" },
        ],
      },
    },
    {
      name: "transmission",
      label: "transmission",
      validate: [required],
      component: SelectRedux,
      cellProps: { md: 3 },
      SelectProps: {
        options: [
          { value: "AUTO", label: "AUTO" },
          { value: "MANUAL", label: "MANUAL" },
        ],
        disabled: false,
      },
    },
    {
      name: "numberOfSeats",
      label: "number-Of-seats",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
      InputProps: {
        disabled: false,
      },
    },
    {
      name: "numberOfBags",
      label: "number-Of-bags",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
      InputProps: {
        disabled: false,
      },
    },
    {
      name: "numberOfDoors",
      label: "number-Of-doors",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
      InputProps: {
        disabled: false,
      },
    },
    {
      name: "numberOfDoors",
      label: "number-Of-doors",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
      InputProps: {
        disabled: false,
      },
    },
  ];
};

export const costSpecificFields = (): ReduxFormField[] => {
  return [
    {
      name: "isUnlimitedKM",
      label: "unlimited-KM",
      component: CheckboxRedux,
      cellProps: { md: 12 },
      CheckBoxProps: {
        disabled: false,
      },
    },
    {
      name: "dailyRate",
      label: "daily-rate",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
    },
    {
      name: "weeklyRate",
      label: "weekly-rate",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
    },
    {
      name: "monthlyRate",
      label: "monthly-rate",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
    },
    {
      name: "extraKMCost",
      label: "extra-KM-cost",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
    },
    {
      name: "freeKMPerDay",
      label: "free-KM/Day",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 3 },
    },
  ];
};
