import Input from "../Input";
import { ComboBoxOwnProps } from ".";
import { Chip } from "@mui/material";
import Autocomplete, { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";

export type ComboBoxProps = ComboBoxOwnProps &
  Omit<React.ComponentProps<typeof Autocomplete>, "renderInput">;

export default function ComboBox({
  options = [],
  ChipProps,
  InputProps,
  onChange,
  ...rest
}: ComboBoxProps) {
  const { t } = useTranslation();
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: unknown,
    reason: AutocompleteChangeReason,
    details?: any,
  ) => {
    if (Array.isArray(newValue)) {
      const uniqueValues = Array.from(new Set(newValue.map((option: any) => option.value)))
        .map((value) => newValue.find((option: any) => option.value === value));

      onChange && onChange(event, uniqueValues, reason, details);
    }
  };
  return (
    <Autocomplete
      {...rest}
      options={options}
      onChange={handleChange}
      getOptionLabel={(option: any) => t(option.label)}
      renderTags={(options: any[], getTagProps) =>
        options.map((option: any, index: number) => (
          <Chip
            label={t(option?.label) || t(option)}
            variant="filled"
            size={"small"}
            {...getTagProps({ index })}
            {...ChipProps}
          />
        ))
      }
      renderInput={(params) => (
        <Input
          {...params}
          {...InputProps}
          inputProps={{
            autoComplete: "new-password",
            form: { autoComplete: "off" },
            ...params.inputProps,
            ...InputProps?.inputProps,
          }}
        />
      )}
    />
  );
}
