import { Container } from "@mui/material";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import PromotionList from "./PromotionList";
import Button from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Promotion() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Container maxWidth="xl">
      <PageHeader
        title={t("promotions")}
        renderRight={
          <Button
            variant="contained"
            onClick={() => navigate("/add-promotion")}
          >
            {t("add-promotion")}
          </Button>
        }
      />
      <PromotionList showFilters />
    </Container>
  );
}
