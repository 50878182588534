import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import EmployeeService from "services/employee.service";
import { employeeActions } from "redux/slices/employee";
import { E_CURRENT_STATUS } from "enum";

export default function UpdateEmployeeForm({ id }: any) {
  const form = "AddEmployeeForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const employee = useAppSelector((state) => state.employee.employee);

  useEffect(() => {
    EmployeeService.getEmployee(id || "");

    return () => {
      dispatch(employeeActions.setEmployee(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!employee) return;

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      designation,
      employeeNumber,
      companyId,
      franchiseId,
      address,
      description,
      currentStatus,
    } = employee;

    dispatch(
      change(form, "currentStatus", currentStatus === E_CURRENT_STATUS.ACTIVE)
    );
    dispatch(change(form, "firstName", firstName));
    dispatch(change(form, "lastName", lastName));
    dispatch(change(form, "email", email));
    dispatch(change(form, "designation", designation));
    dispatch(change(form, "employeeNumber", employeeNumber));
    dispatch(change(form, "companyId", companyId?._id));
    dispatch(change(form, "franchiseId", franchiseId?._id));
    dispatch(
      change(form, "phone", {
        value: phoneNumber?.dialingCode + phoneNumber.number,
      })
    );
    dispatch(
      change(form, "address", { value: address.formattedAddress, address })
    );
    dispatch(change(form, "description", description));
  }, [employee, navigate, dispatch]);

  return null;
}
