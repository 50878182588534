import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import BranchService from "services/branch.service";
import { branchActions } from "redux/slices/branch";
import { days } from "../AddBranchForm";
import { E_CURRENT_STATUS } from "enum";

export default function UpdateBranchForm({ id }: any) {
  const form = "AddBranchForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const branch = useAppSelector((state) => state.branch.branch);

  useEffect(() => {
    BranchService.getBranch(id || "");

    return () => {
      dispatch(branchActions.setBranch(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!branch) return;

    const {
      name,
      currentStatus,
      branchCode,
      supplierId,
      address,
      email,
      phoneNumber,
      description,
      workingHours,
    } = branch;

    const initialWorkingHours = workingHours?.map((item: any, index: any) => ({
      [days[index]]: !item?.isHoliday,
      is24Hours: item?.is24Hours,
      isHoliday: item?.isHoliday,
      startTime: {
        date: new Date().toISOString().split("T")[0] + `T${item.startTime}`,
      },
      endTime: {
        date: new Date().toISOString().split("T")[0] + `T${item.endTime}`,
      },
    }));

    dispatch(
      change(form, "currentStatus", currentStatus === E_CURRENT_STATUS.ACTIVE)
    );
    dispatch(change(form, "name", name));
    dispatch(change(form, "branchCode", branchCode));
    dispatch(change(form, "supplierId", supplierId?._id));
    dispatch(
      change(form, "address", { value: address.formattedAddress, address })
    );
    dispatch(
      change(form, "phone", {
        value: phoneNumber?.dialingCode + phoneNumber.number,
      })
    );
    dispatch(change(form, "email", email));
    dispatch(change(form, "workingHours", workingHours));

    dispatch(change("AddBranchForm", "workingHours", initialWorkingHours));

    dispatch(change(form, "description", description));
  }, [branch, navigate, dispatch]);

  return null;
}
