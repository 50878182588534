import Container from "@mui/material/Container";
import AddCouponsForm from "./AddCouponsForm";
import CouponsService from "services/coupons.service";
import { useParams } from "react-router-dom";

export default function AddCoupons() {
  const { id } = useParams();

  const handleSubmit = (values: any) => {
    let data = { ...values };
    data.promotionId = id;

    if (values?.code) {
      CouponsService.addCouponCode(data, `${id}`);
    }
    if (values?.numberOfCoupons) {
      data.numberOfCoupons = Number(values?.numberOfCoupons);
      CouponsService.addCouponAuto(data, `${id}`);
    }
  };
  return (
    <div>
      <Container maxWidth="xl">
        <div className="form">
          <AddCouponsForm onSubmit={handleSubmit} />
        </div>
      </Container>
    </div>
  );
}
