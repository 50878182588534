export function currencyFormatter(value: number | string) {
  const options = {
    style: "currency",
    currency: "SAR",
    numberingSystem: "latn",
  } as Intl.NumberFormatOptions & { numberingSystem: string };
  return new Intl.NumberFormat("SAR", options).format(Number(value));
  // return new Intl.NumberFormat("ar-SA", options).format(Number(value));

  // return new Intl.NumberFormat("ar-SA", {
  //   style: "currency",
  //   currency: "SAR",
  //   numberingSystem: "latn",
  // }).format(Number(value));
}
