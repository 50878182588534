import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PromotionService from "services/promotion.service";
import { promotionActions } from "redux/slices/promotion";
import { E_CURRENT_STATUS } from "enum";

export default function UpdatePromotionForm({ id }: any) {
  const form = "AddPromotionForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const promotion = useAppSelector((state) => state.promotion.promotion);
  const modelOptions = useAppSelector(
    (state) => state.carModel.carModelOptions
  );

  useEffect(() => {
    PromotionService.getPromotion(id || "");

    return () => {
      dispatch(promotionActions.setPromotion(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!promotion) return;

    const {
      title,
      description,
      minOrderAmount,
      usageLimit,
      discountType,
      discountValue,
      emailSpecific,
      modelSpecific,
      locationSpecific,
      dateTimeSpecific,
      currentStatus,
    } = promotion;

    dispatch(change(form, "title", title));
    dispatch(change(form, "description", description));
    dispatch(change(form, "minOrderAmount", minOrderAmount));
    dispatch(change(form, "usageLimit", usageLimit));
    dispatch(change(form, "discountType", discountType));
    dispatch(change(form, "discountValue", discountValue));
    dispatch(
      change(
        form,
        "emails",
        emailSpecific?.emails?.map((email: string) => ({
          value: email,
          label: email,
        }))
      )
    );
    dispatch(
      change(
        form,
        "locationSpecific",
        locationSpecific?.locations?.map((elem: any) => ({
          ...elem,
          address: {
            ...elem.address,
            value: elem?.address?.formattedAddress,
          },
        }))
      )
    );
    dispatch(change(form, "isActiveEmailSpecific", emailSpecific?.isActive));
    dispatch(change(form, "isActiveModelSpecific", modelSpecific?.isActive));
    dispatch(
      change(form, "currentStatus", currentStatus === E_CURRENT_STATUS.ACTIVE)
    );
    dispatch(
      change(form, "isActiveLocationSpecific", locationSpecific?.isActive)
    );
    dispatch(
      change(form, "isActiveDateTimeSpecific", dateTimeSpecific?.isActive)
    );

    if (dateTimeSpecific)
      dispatch(
        change(
          form,
          "dateTimeSpecific",
          dateTimeSpecific.dates.map((date: any) => ({
            startTime: {
              error: [null, null],
              date: [
                new Date(date.startTime).toString(),
                new Date(date.endTime).toString(),
              ],
            },
          }))
        )
      );
  }, [promotion, navigate, dispatch]);

  useEffect(() => {
    if (
      promotion?.modelSpecific?.models?.length > 0 &&
      modelOptions?.length > 0
    ) {
      const updatedModels = promotion.modelSpecific.models.map(
        (model: string) => {
          const option = modelOptions.find(
            (opt: any) =>
              String(opt.value).toLowerCase() === String(model).toLowerCase()
          );

          return {
            value: model,
            label: option ? option.label : model,
          };
        }
      );

      dispatch(change(form, "modelSpecific", updatedModels));
    }
  }, [dispatch, form, modelOptions, promotion?.modelSpecific?.models]);

  return null;
}
