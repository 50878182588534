import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import AddPromotionForm from "./AddPromotionForm";
import UpdatePromotionForm from "./UpdatePromotionForm";
import PromotionService from "services/promotion.service";
import { E_CURRENT_STATUS } from "enum";
import { useTranslation } from "react-i18next";

export default function AddPromotion() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const promotion = useAppSelector((state) => state.promotion.promotion);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    const {
      title,
      description,
      discountType,
      discountValue,
      usageLimit,
      minOrderAmount,
      emailSpecific,
      locationSpecific,
      modelSpecific,
      dateTimeSpecific,
      emails,
      isActiveEmailSpecific,
      isActiveModelSpecific,
      isActiveLocationSpecific,
      isActiveDateTimeSpecific,
    } = values;

    const data: any = {
      title,
      description,
      discountType,
      discountValue: +discountValue,
      usageLimit: +usageLimit,
      minOrderAmount: +minOrderAmount,
      emailSpecific,
      locationSpecific,
      modelSpecific,
      dateTimeSpecific,
    };

    if (values.emails?.length > 0) {
      data.emailSpecific = {
        emails: emails.map((email: { value: string }) => email.value) ?? [],
        isActive: isActiveEmailSpecific ?? false,
      };
    } else delete data.emailSpecific;

    if (values.modelSpecific?.length > 0) {
      data.modelSpecific = {
        models:
          modelSpecific.map((model: { value: string }) => model.value) ?? [],
        isActive: isActiveModelSpecific ?? false,
      };
    } else delete data.modelSpecific;

    if (values.locationSpecific?.length > 0) {
      const locations = data.locationSpecific.map(
        ({ address, radiusInKm }: any) => {
          return {
            address: address?.address ?? address,
            radiusInKm: radiusInKm || 0,
          };
        }
      );

      if (
        locations.some(
          (location: any) => location?.address?.lat && location?.address?.lng
        )
      ) {
        data.locationSpecific = {
          locations,
          isActive: isActiveLocationSpecific ?? false,
        };
      } else {
        delete data.locationSpecific;
      }
    }

    if (values.dateTimeSpecific?.length > 0) {
      const dates = data.dateTimeSpecific
        .map((item: any) => {
          if (item.startTime.date[0] !== "" && item.startTime.date[1] !== "") {
            return {
              startTime: new Date(item.startTime.date[0]),
              endTime: new Date(item.startTime.date[1]),
            };
          }
          return null;
        })
        .filter(Boolean);

      if (dates.length > 0) {
        data.dateTimeSpecific = {
          dates,
          isActive: isActiveDateTimeSpecific ?? false,
        };
      } else {
        delete data.dateTimeSpecific;
      }
    } else {
      delete data.dateTimeSpecific;
    }

    data.currentStatus = values?.currentStatus
      ? E_CURRENT_STATUS.ACTIVE
      : E_CURRENT_STATUS.INACTIVE;

    console.dir({ data }, { depth: null });

    if (id) {
      PromotionService.updatePromotion(`${id}`, data, navigate);
    } else PromotionService.addPromotion(data, navigate);
  };

  return (
    <Container maxWidth="xl">
      <GoBack
        path={`${
          id && promotion !== "Not Found"
            ? `/promotion-details/${id}`
            : "/promotions"
        }`}
        title={`${t("back-to")} ${
          id && promotion !== "Not Found"
            ? t("promotion-details")
            : t("promotions")
        } `}
      />
      <PageHeader title={`${id ? t("update") : t("add")} ${t("promotions")}`} />
      <div className="form">
        {loading && <CircleLoader />}
        {id && !promotion ? (
          <p>Promotion Not Found</p>
        ) : (
          <AddPromotionForm onSubmit={handleSubmit} />
        )}
        {id && <UpdatePromotionForm id={id} />}
      </div>
    </Container>
  );
}
