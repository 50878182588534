import { useEffect } from "react";
import { Chip as MuiChip, Container, Stack, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./SupplierDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import SupplierService from "services/supplier.service";
import { supplierActions } from "redux/slices/supplier";
import { useTranslation } from "react-i18next";

export default function SupplierDetails() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const supplier = useAppSelector((state) => state.supplier.supplier);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    SupplierService.getSupplier(id || "");
    return () => {
      dispatch(supplierActions.setSupplier(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/suppliers" title={t("back-to-supplier")} />
      <PageHeader title={t("supplier-details")} />
      {loading && <CircleLoader />}
      {id && !loading && !supplier ? (
        <p>{t("supplier-not-found")}</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={t(supplier?.currentStatus)} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-supplier/${supplier._id}`)}
            >
              {t("edit")}
            </Button>
          </Stack>
          <div
            style={{
              position: "absolute",
              top: "24px",
              [i18n.language === 'ar' ? 'left' : 'right']: "24px",
              maxWidth: "250px",
              maxHeight: "250px",
            }}
          >
            <img
              src={supplier?.businessLogo}
              alt={supplier?.legalName}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
          <Grid container>
            <Grid item md={6}>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("supplier-id")}</label>
                <p>{supplier?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("legal-name")}</label>
                <p>{supplier?.legalName}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("phone")}</label>
                <p>
                  +{supplier?.phoneNumber?.dialingCode}
                  {supplier?.phoneNumber?.number}
                </p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("email")}</label>
                <p>{supplier?.email}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("address")}</label>
                <p>{supplier?.address?.formattedAddress}</p>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("name")}</label>
                <p>{supplier?.name}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("main-contact-person")}</label>
                <p>{supplier?.mainContactPerson}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("registration-number")}</label>
                <p>{supplier?.registrationNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("tax-ID")}</label>
                <p>{supplier?.taxId}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("number-Of-vehicles")}</label>
                <p>{supplier?.numberOfVehicles}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}
