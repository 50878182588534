import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InsuranceService from "services/insurance.service";
import { insuranceActions } from "redux/slices/insurance";
import { E_CURRENT_STATUS } from "enum";

export default function UpdateInsuranceForm({ id }: any) {
  const form = "AddInsuranceForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const insurance = useAppSelector((state) => state.insurance.insurance);

  useEffect(() => {
    InsuranceService.getInsurance(id || "");

    return () => {
      dispatch(insuranceActions.setInsurance(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!insurance) return;

    const {
      currentStatus,
      name,
      description,
      info,
      excludes,
      zero_to_one,
      one_to_two,
      two_to_three,
      three_to_four,
      more_than_four,
    } = insurance;

    dispatch(
      change(form, "currentStatus", currentStatus === E_CURRENT_STATUS.ACTIVE)
    );
    dispatch(change(form, "name", name));
    dispatch(change(form, "description", description));
    dispatch(change(form, "info", info));
    dispatch(change(form, "zero_to_one", zero_to_one));
    dispatch(change(form, "one_to_two", one_to_two));
    dispatch(change(form, "two_to_three", two_to_three));
    dispatch(change(form, "three_to_four", three_to_four));
    dispatch(change(form, "more_than_four", more_than_four));
    if (excludes?.length > 0) {
      let excludesL = excludes?.map((item: any) => ({
        label: item,
        value: item,
      }));

      dispatch(change(form, "excludes", excludesL));
    }
  }, [insurance, navigate, dispatch]);

  return null;
}
