import React from "react";
import UpdateBookingDateTimeForm from "./UpdateBookingDateTimeForm";

const UpdateBookingDateTime = () => {
  const handleSubmit = (values: any) => {};
  return (
    <UpdateBookingDateTimeForm
    // onSubmit={handleSubmit}
    />
  );
};

export default UpdateBookingDateTime;
