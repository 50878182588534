import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarExtraService from "services/carExtra.service";
import { carExtraActions } from "redux/slices/carExtra";
import { E_CURRENT_STATUS } from "enum";

export default function UpdateCarExtraForm({ id }: any) {
  const form = "AddCarExtraForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carExtra = useAppSelector((state) => state.carExtra.carExtra);

  useEffect(() => {
    CarExtraService.getCarExtra(id || "");

    return () => {
      dispatch(carExtraActions.setCarExtra(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!carExtra) return;

    const { supplierId, modelId, extras, currentStatus } = carExtra;

    dispatch(
      change(form, "currentStatus", currentStatus === E_CURRENT_STATUS.ACTIVE)
    );
    dispatch(change(form, "supplierId", supplierId?._id));
    dispatch(change(form, "modelId", modelId?._id));
    dispatch(change(form, "extras", extras));
  }, [carExtra, navigate, dispatch]);

  return null;
}
