import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { couponActions } from "redux/slices/coupon";
import PromotionService from "./promotion.service";
import { reset } from "redux-form";

const url = "/api/promotions/coupon";

const CouponsService = {
  getCoupon: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const coupon = success.data;
      dispatch?.(couponActions.setCoupon(coupon));
    } else dispatch?.(couponActions.setCoupon({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getCoupons: async () => {
    const dispatch = getAppDispatch();

    dispatch?.(couponActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(couponActions.setCoupons({ coupons: documents }));
      dispatch?.(couponActions.setTotalCount({ totalCount }));
    }

    dispatch?.(couponActions.setLoading(false));
    return [success, error];
  },

  getSupplierCoupons: async (id: string) => {
    const dispatch = getAppDispatch();

    dispatch?.(couponActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?supplierId=${id}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(couponActions.setSupplierCoupons({ coupons: documents }));
      dispatch?.(couponActions.setTotalCount({ totalCount }));
    }

    dispatch?.(couponActions.setLoading(false));
    return [success, error];
  },

  addCouponCode: async (data: any, promotionId: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/manual-generate`, data)
    );

    if (success) {
      const coupon = success.data;
      dispatch?.(reset("AddCouponsForm"));
      dispatch?.(couponActions.addCoupon(coupon));
      PromotionService.getPromotion(promotionId);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  addCouponAuto: async (data: any, promotionId: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/auto-generate`, data)
    );

    if (success) {
      const coupon = success.data;
      dispatch?.(couponActions.addCoupon(coupon));
      PromotionService.getPromotion(promotionId);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCouponStatus: async (id: string, promotionId: string, data: any) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const coupon = success.data;
      dispatch?.(couponActions.setCoupon(coupon));
      dispatch?.(modalActions.closeModal());
      PromotionService.getPromotion(promotionId);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteCoupon: async (id: string, promotionId: string) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(modalActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(couponActions.deleteCouponById(id));
      dispatch?.(modalActions.closeModal());
      PromotionService.getPromotion(promotionId);
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CouponsService;
