import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoyaltyTierState } from ".";

const initialState: LoyaltyTierState = {
  loyaltyTiers: [],
  loyaltyTier: null,
  totalCount: 0,
  refresh: 0,
  refreshLoader: false,
  loading: true,
};

export const loyaltyTierSlice = createSlice({
  name: "loyaltyTier",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateLoyalTier: (state, action) => {
      const { id, loyaltyTier } = action.payload;
      state.loyaltyTiers.every(({ _id }, i) => {
        if (id === _id) {
          state.loyaltyTiers[i] = loyaltyTier;
          return false;
        }
        return true;
      });
    },

    setLoyaltyTier: (state, action) => {
      state.loyaltyTier = action.payload;
    },
    setLoyaltyTiers: (state, action: PayloadAction<any>) => {
      const { loyaltyTiers } = action.payload;
      state.loyaltyTiers = loyaltyTiers;
    },
  },
});

const loyaltyTierReducer = loyaltyTierSlice.reducer;
export const loyaltyTierActions = loyaltyTierSlice.actions;
export default loyaltyTierReducer;
