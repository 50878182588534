import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddCarSpecsForm from "./AddCarSpecsForm";
import UpdateCarSpecsForm from "./UpdateCarSpecsForm";
import CarSpecService from "services/carSpec.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";

export default function AddCarSpec() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const carSpec = useAppSelector((state) => state.carSpec.carSpec);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };

    data.numberOfBags = Number(values.numberOfBags)
    data.numberOfDoors = Number(values.numberOfDoors)
    data.numberOfSeats = Number(values.numberOfSeats)
    data.unlimitedKM = Number(values.unlimitedKM);
    if (id) {
      CarSpecService.updateCarSpec(`${id}`, data, navigate);
    } else CarSpecService.addCarSpec(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && carSpec ? `/car-specs-details/${id}` : "/car-specs"}`}
          title={`${t("back-to")} ${id && carSpec ? t("specs-details") : t("car-specs")} `}
        />
        <PageHeader title={`${id ? t("update") : t("add")} ${t("specs")}`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && carSpec === "Not found" ? (
            <p>Car Specs Not Found</p>
          ) : (
            <AddCarSpecsForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateCarSpecsForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
