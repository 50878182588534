export enum E_BOOKING_STATUS {
  DRAFT = "draft",
  CONFIRM = "confirm",
  PARTIAL_CONFIRM = "partial_confirm",
  NOT_CONFIRMED = "not_confirmed",
  INPROGRESS = "inprogress",
  FINISH = "finish",
  CANCELLED = "cancelled",
}
export enum E_DISCOUNT_REFERENCE {
  COUPON = "coupon",
  REFERENCE = "reference",
  NONE = "none",
}
export enum E_BOOKING_PAYMENT_STATUS {
  PENDING = "pending",
  VOID = "void",
  PAID = "paid",
  PARTIAL_PAID = "partial_paid",
  REFUND = "refund",
  PARTIAL_REFUND = "partial_refund",
}

export enum E_CAR_FUEL_TYPE {
  PETROL = "PETROL",
  ELECTRIC = "ELECTRIC",
  HYBRID = "HYBRID",
  DIESEL = "DIESEL",
}

export enum E_CAR_TRANSMISSION_TYPE {
  AUTO = "AUTO",
  Manual = "MANUAL",
}

export enum E_CURRENT_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum E_BUDGET_REQUEST_STATUS {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export enum E_PRICE_PLAN_TYPE {
  FIXED_PRICE = "fixed_price",
  FIXED_PERCENTAGE = "fixed_percentage", // this price will be calculated from both end (customer + Broker)
  SHARED_PERCENTAGE = "shared_percentage",
}

export enum E_EMAIL_TEMPLATES {
  WELCOME = "welcome",
  BOOKING_OTP = "booking_otp",
  BOOKING_CONFIRMATION = "booking_confirmation",
}

export enum E_EXTRA_NAME {
  CHILD_SEAT = "Child Seat",
  UNLIMITED_KM = "Unlimited KM",
  ADDITIONAL_DRIVER = "Additional Driver",
  CAR_DELIVERY = "Car Delivery",
}

export enum E_LOGS_ACTION_PERFORMED {
  CREATED = "CREATED",
  UPDATED = "UPDATED",
  FEEDBACK = "FEEDBACK",
  CANCELED = "CANCELLED",
  PAYMENT_UPDATED = "PAYMENT UPDATED",
  REFUND_INITIATED = "REFUND INITIATED",
  ADMIN_NOTE = "ADMIN NOTE",
}

export enum E_DEFAULT_PAYMENT_METHOD {
  PAYFORT = "payfort",
  APPLE_PAY = "apple_pay",
  CLICK_PAY = "click_pay",
}

export enum E_ROLES {
  COMFY_ADMIN = "comfy_admin",
  COMFY_SALES_MANAGER = "comfy_sales_manager",
  COMFY_OPERATIONS_MANAGER = "comfy_operations_manager",
  SUPPLIER_ADMIN = "supplier_admin",
  SUPPLIER_BRANCH_MANGER = "supplier_branch_manager",
  COMPANY_ADMIN = "company_admin",
  COMPANY_BRANCH_MANAGER = "company_branch_manager",
  USER = "user",
  GUEST = "guest",
}

export enum E_STRING_TYPE {
  CANCELLATION_REASONS = "cancellation_reasons",
  CAR_INCLUDE_TAGS = "car_includes_tags",
  DELETE_ACCOUNT_REASONS = "delete_account_reasons",
  SUPPORT_TICKET_REASONS = "support_ticket_reasons",
}

export enum E_LANGUAGE {
  AR = "ar",
  EN = "en",
}

export enum E_CURRENCY {
  SAR = "SAR",
}

export enum E_PAYFORT_REMEMBER_ME {
  YES = "YES",
  NO = "NO",
}

export enum E_PAYFORT_RETURN_THIRD_PARTY_RESPONSE_CODES {
  YES = "YES",
  NO = "NO",
}

export enum E_PAYFORT_COMMAND {
  SDK_TOKEN = "SDK_TOKEN",
  CREATE_TOKEN = "CREATE_TOKEN",
  TOKENIZATION = "TOKENIZATION",
  REFUND = "REFUND",
  AUTHORIZATION = "AUTHORIZATION",
  PURCHASE = "PURCHASE",
  CHECK_STATUS = "CHECK_STATUS",
}

export enum E_PAYFORT_PAYMENT_OPTIONS {
  VISA = "VISA",
  AMEX = "AMEX",
  MADA = "MADA",
  MEEZA = "MEEZA",
  MASTERCARD = "MASTERCARD",
}

export enum E_PAYFORT_ECOMMERCE_INDICATOR {
  ECOMMERCE = "ECOMMERCE",
  MOTO = "MOTO",
  RECCURING = "RECCURING",
}

export enum E_COUPON_TYPES {
  EMAIL_SPECIFIC = "email",
  VALID_FOR_ALL = "valid_for_all",
  LOCATION_SPECIFIC = "location",
  DATE_TIME_SPECIFIC = "date_time",
  CAR_TYPE_SPECIFIC = "car_type",
}

export enum E_DISCOUNT_TYPES {
  FIXED = "fixed",
  PERCENTAGE = "percentage",
}

export enum E_GENDER {
  MR = "Mr",
  MRS = "Mrs",
  MS = "Ms",
}

export enum E_SIGNUP_SOURCE {
  PHONE = "phone",
  GOOGLE = "google.com",
  COMFY_ADMIN = "comfy_admin",
  SUPPLIER_ADMIN = "supplier_admin",
  COMPANY_ADMIN = "company_admin",
}

export enum E_LOYALTY_POINTS_ACTION {
  SIGN_UP = "SIGN_UP",
  ADD_BOOKING = "ADD_BOOKING",
}
