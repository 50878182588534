import { useEffect, useState, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import UserService from "services/user.service";
import { userActions } from "redux/slices/user";
import SelectReactRedux from "components/molecules/SelectReactRedux";
import debounce from "lodash.debounce";

export default function SelectEmail(props: any) {
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.user.userOptions);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const hasMore = useMemo(() => users.length % 30 === 0, [users]);

  useEffect(() => {
    loadUserOptions();

    return () => {
      dispatch(userActions.clear());
    };
  }, [dispatch]);

  const loadUserOptions = async (inputValue = "", newPage = 1) => {
    setLoading(true);
    const [success] = await UserService.getUserOptions({
      page: newPage,
      pageSize: 30,
      ...(inputValue && { email: inputValue }),
    });
    setLoading(false);
    setPage(newPage);
  };

  const debouncedLoadUserOptions = useCallback(
    debounce((inputValue: string, newPage: number, callback: (options: Array<{ value: string; label: string }>) => void) => {
      loadUserOptions(inputValue, newPage).then(() => {
        callback(users);
      });
    }, 300),
    [users]
  );

  const handleLoadOptions = (
    inputValue: string,
    callback: (options: Array<{ value: string; label: string }>) => void
  ) => {
    setInputValue(inputValue);
    debouncedLoadUserOptions(inputValue, 1, callback);
  };

  const handleMenuScrollToBottom = () => {
    if (hasMore) {
      loadUserOptions("", page + 1);
    }
  };

  const handleChange = (value: any) => {
    setInputValue("");
    props.input.onChange(value);
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
    if (newValue === "") {
      loadUserOptions("", 1);
    }
  };

  return (
    <SelectReactRedux
      {...props}
      options={users}
      loadOptions={handleLoadOptions}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      isLoading={loading}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isMulti
      placeholder=""
    />
  );
}
