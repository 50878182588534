import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddBranchForm, { days } from "./AddBranchForm";
import UpdateBranchForm from "./UpdateBranchForm";
import BranchService from "services/branch.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { phoneFormat } from "utils/phone.util";
import moment from "moment";
import { E_CURRENT_STATUS } from "enum";
import { useTranslation } from "react-i18next";

export default function AddBranch() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const branch = useAppSelector((state) => state.branch.branch);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };

    data.address = values.address.address;
    data.phoneNumber = phoneFormat(
      values?.phone?.data?.countryCode ? values?.phone : branch?.phoneNumber
    );

    data.workingHours = values.workingHours.map((item: any, index: number) => {
      return {
        day: days[index],
        is24Hours: item.is24Hours,
        isHoliday: item.isHoliday,
        startTime: moment(item.startTime.date).format("HH:mm"),
        endTime: moment(item.endTime.date).format("HH:mm"),
      };
    });

    data.currentStatus = values?.currentStatus
      ? E_CURRENT_STATUS.ACTIVE
      : E_CURRENT_STATUS.INACTIVE;

    delete data.phone;

    if (values.address?.address?._id) {
      const { _id, ...addressWithoutId } = values.address.address;
      data.address = addressWithoutId;
    }
    if (id) {
      BranchService.updateBranch(`${id}`, data, navigate);
    } else BranchService.addBranch(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && branch ? `/branch-details/${id}` : "/branches"}`}
          title={`${t("back-to")} ${id && branch ? t('branch-details') : t("branches")} `}
        />
        <PageHeader title={`${id ? t("update") : t("add")} ${t("branch")}`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && branch === "Not found" ? (
            <p>Branch Not Found</p>
          ) : (
            <AddBranchForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateBranchForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
