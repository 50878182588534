import { useEffect } from "react";
import { Container, Grid, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./CarModelDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import CarModelService from "services/carModel.service";
import { carModelActions } from "redux/slices/carModel";
import { useTranslation } from "react-i18next";

export default function CarModelDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carModel = useAppSelector((state) => state.carModel.carModel);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    CarModelService.getCarModel(id || "");
    return () => {
      dispatch(carModelActions.setCarModel(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/car-models" title={t("back-to-models")} />
      <PageHeader title={t("model-details")} />
      {loading && <CircleLoader />}
      {!loading && !carModel ? (
        <p>{t("model-not-found")}</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={t(carModel?.currentStatus)} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-car-model/${carModel._id}`)}
            >
              {t("edit")}
            </Button>
          </Stack>
          <Grid container>
            <Grid item md={6}>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("model-ID")}</label>
                <p>{carModel?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("name")}</label>
                <p>{carModel?.name}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("manufacturer")}</label>
                <p>{carModel?.manufacturer}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("category")}</label>
                <br />
                <Link
                  to={`/car-categories`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {carModel?.carCategoryId?.name}
                </Link>
              </div>
            </Grid>
            <Grid item md={6}>
              <div style={{ textAlign: "right" }}>
                <img
                  src={carModel?.carImage}
                  alt={carModel?.name}
                  style={{ height: "200px", maxWidth: "100%" }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("number-Of-seats")}</label>
                <p>{carModel?.numberOfSeats}</p>
              </div>
            </Grid>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("number-Of-bags")}</label>
                <p>{carModel?.numberOfBags}</p>
              </div>
            </Grid>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("number-Of-doors")}</label>
                <p>{carModel?.numberOfDoors}</p>
              </div>
            </Grid>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("transmission")}</label>
                <p>{carModel?.transmission}</p>
              </div>
            </Grid>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("air-conditioned")}</label>
                <p>{carModel?.isAirConditioned ? t("yes") : t("no")}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}
