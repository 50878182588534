import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { StylesConfig } from "react-select";
import { ReactSelectProps } from ".";
import { useTranslation } from "react-i18next";

const customStyles: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    borderColor: state.isFocused ? "#000000" : "#dbdbdb",
    "&:hover": {
      borderColor: "#000000",
    },
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    marginTop: "20px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "6.5px 8px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "rgba(42, 148, 70, 0.1)"
      : state.isFocused
        ? "rgba(237, 28, 36, 0.1)"
        : provided.backgroundColor,
    color: state.isSelected ? "rgba(42, 148, 70, 1)" : provided.color,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "rgba(237, 28, 36, 0.1)",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "black",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "black",
    ":hover": {
      backgroundColor: "rgba(237, 28, 36, 0.2)",
      color: "black",
    },
  }),
};

export default function ReactSelect({
  disabled,
  disabledOnUpdate,
  options = [],
  loadOptions,
  input,
  meta,
  inputValue,
  onInputChange,
  ...rest
}: ReactSelectProps) {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel shrink htmlFor={`${rest?.id}`}>
        {t(`${rest?.label}`)}
      </InputLabel>
      <AsyncSelect
        cacheOptions
        defaultOptions={options}
        loadOptions={loadOptions}
        isDisabled={disabledOnUpdate && id ? true : disabled}
        inputValue={inputValue}
        onInputChange={onInputChange}
        styles={customStyles}
        {...input}
        {...rest}
      />
      {meta?.touched && meta?.error && (
        <FormHelperText sx={{ color: "#b72136" }} id="component-error-text">
          {meta?.error}
        </FormHelperText>
      )}
    </FormControl>
  );
}
