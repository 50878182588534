import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarCategoryService from "services/carCategory.service";
import { carCategoryActions } from "redux/slices/carCategory";
import { E_CURRENT_STATUS } from "enum";

export default function UpdateCarCategoryForm({ id }: any) {
  const form = "AddCarCategoryForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carCategory = useAppSelector((state) => state.carCategory.carCategory);

  useEffect(() => {
    CarCategoryService.getCarCategory(id || "");

    return () => {
      dispatch(carCategoryActions.setCarCategory(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!carCategory) return;

    const { name, description, currentStatus } = carCategory;

    dispatch(
      change(form, "currentStatus", currentStatus === E_CURRENT_STATUS.ACTIVE)
    );
    dispatch(change(form, "name", name));
    dispatch(change(form, "description", description));
  }, [carCategory, navigate, dispatch]);

  return null;
}
