import { useEffect } from "react";
import { supplierActions } from "redux/slices/supplier";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch } from "redux/hooks";
import CarModelService from "services/carModel.service";
import { E_EXTRA_NAME } from "enum";

export default function SelectExtraName(props: any) {
  const dispatch = useAppDispatch();

  const extraName = [
    { label: E_EXTRA_NAME.CHILD_SEAT, value: E_EXTRA_NAME.CHILD_SEAT },
    { label: E_EXTRA_NAME.UNLIMITED_KM, value: E_EXTRA_NAME.UNLIMITED_KM },
    {
      label: E_EXTRA_NAME.ADDITIONAL_DRIVER,
      value: E_EXTRA_NAME.ADDITIONAL_DRIVER,
    },
    { label: E_EXTRA_NAME.CAR_DELIVERY, value: E_EXTRA_NAME.CAR_DELIVERY },
  ];

  useEffect(() => {
    CarModelService.getCarModels();

    return () => {
      dispatch(supplierActions.clear());
    };
  }, [dispatch]);

  return <SelectRedux {...props} options={extraName} />;
}
