import React from "react";
import UpdateBookingInsuranceForm from "./UpdateBookingInsuranceForm";

const UpdateBookingInsurance = () => {
  const handleSubmit = (values: any) => {};
  return (
    <UpdateBookingInsuranceForm
    // onSubmit={handleSubmit}
    />
  );
};

export default UpdateBookingInsurance;
