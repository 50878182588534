import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddFranchiseForm from "./AddFranchiseForm";
import UpdateFranchiseForm from "./UpdateFranchiseForm";
import FranchiseService from "services/franchise.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { phoneFormat } from "utils/phone.util";
import { E_CURRENT_STATUS } from "enum";
import { useTranslation } from "react-i18next";

export default function AddFranchise() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const franchise = useAppSelector((state) => state.franchise.franchise);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };

    data.phoneNumber = phoneFormat(
      values?.phone?.data?.countryCode ? values?.phone : franchise?.phoneNumber
    );

    data.address = values.address.address;
    data.numberOfEmployees = Number(values.numberOfEmployees);
    data.dateOfEstablishment = values.dateOfEstablishment.date;
    data.currentStatus = values?.currentStatus
      ? E_CURRENT_STATUS.ACTIVE
      : E_CURRENT_STATUS.INACTIVE;

    delete data.phone;

    if (values.address.address._id) {
      const { _id, ...addressWithoutId } = values.address.address;
      data.address = addressWithoutId;
    }
    if (id) {
      FranchiseService.updateFranchise(`${id}`, data, navigate);
    } else FranchiseService.addFranchise(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && franchise ? `/franchise-details/${id}` : "/franchises"
            }`}
          title={`${t("back-to")} ${id && franchise ? t("franchise-details") : t("franchises")
            } `}
        />
        <PageHeader title={`${id ? t("update") : t("add")} ${t("franchise")}`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && franchise === "Not found" ? (
            <p>Franchise Not Found</p>
          ) : (
            <AddFranchiseForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateFranchiseForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
