import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarPriceService from "services/carPrice.service";
import { carPriceActions } from "redux/slices/carPrice";
import { E_CURRENT_STATUS } from "enum";

export default function UpdateCarPriceForm({ id }: any) {
  const form = "AddCarPriceForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carPrice = useAppSelector((state) => state.carPrice.carPrice);

  useEffect(() => {
    CarPriceService.getCarPrice(id || "");

    return () => {
      dispatch(carPriceActions.setCarPrice(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!carPrice) return;

    const {
      name,
      supplierId,
      dailyRate,
      weeklyRate,
      monthlyRate,
      extraKMCost,
      freeKMPerDay,
      isUnlimitedKM,
      currency,
      currentStatus,
    } = carPrice;

    dispatch(
      change(form, "currentStatus", currentStatus === E_CURRENT_STATUS.ACTIVE)
    );
    dispatch(change(form, "name", name));
    dispatch(change(form, "supplierId", supplierId?._id));
    dispatch(change(form, "dailyRate", dailyRate));
    dispatch(change(form, "weeklyRate", weeklyRate));
    dispatch(change(form, "monthlyRate", monthlyRate));
    dispatch(change(form, "extraKMCost", extraKMCost));
    dispatch(change(form, "freeKMPerDay", freeKMPerDay));
    dispatch(change(form, "isUnlimitedKM", isUnlimitedKM));
    dispatch(change(form, "currency", currency));
  }, [carPrice, navigate, dispatch]);

  return null;
}
