import InputRedux from "components/molecules/InputRedux";
import SelectRedux from "components/molecules/SelectRedux";
import SelectEmail from "components/organisms/SelectEmail";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { number, required } from "utils/validate.util";
import ReduxFieldArray from "components/organisms/ReduxFieldArray";
import DateRangePickerRedux from "components/molecules/DateRangePickerRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import SelectModels from "components/organisms/SelectModels";

export { default } from "./AddPromotionForm";

export const fields: ReduxFormField[] = [
  {
    name: "currentStatus",
    label: "is-active",
    component: CheckboxRedux,
    cellProps: { md: 3 },
  },
  {
    name: "isNewUserOnly",
    label: "new-user-only",
    component: CheckboxRedux,
    cellProps: { md: 9 },
  },
  {
    name: "isActiveEmailSpecific",
    label: "emails-specific",
    component: CheckboxRedux,
    cellProps: { md: 3 },
  },

  {
    name: "isActiveModelSpecific",
    label: "models-specific",
    component: CheckboxRedux,
    cellProps: { md: 3 },
  },
  {
    name: "isActiveLocationSpecific",
    label: "location-specific",
    component: CheckboxRedux,
    cellProps: { md: 3 },
  },

  {
    name: "isActiveDateTimeSpecific",
    label: "date-time-specifics",
    component: CheckboxRedux,
    cellProps: { md: 3 },
  },
  {
    name: "title",
    label: "title",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "description",
    label: "description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "minOrderAmount",
    label: "min-order-amount",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "usageLimit",
    label: "usage-limit",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "discountType",
    label: "discount-type",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 4 },
    SelectProps: {
      options: [
        { value: "fixed", label: "fixed" },
        { value: "percentage", label: "percentage" },
      ],
    },
  },
  {
    name: "discountValue",
    label: "discount-value",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "emails",
    label: "emails-specific",
    component: SelectEmail,
    cellProps: { md: 12, lg: 12 },
  },
  {
    name: "modelSpecific",
    label: "model-specific",
    component: SelectModels,
    cellProps: { md: 12, lg: 12 },
  },
  {
    name: "locationSpecific",
    label: "location-specific",
    component: ReduxFieldArray,
    reduxFormComponent: "FieldArray",
    fieldsArray: [
      {
        name: "address",
        label: "address",
        component: AutoCompleteRedux,
        cellProps: { md: 6 },
      },
      {
        name: "radiusInKm",
        label: "searchable-radius(km)",
        validate: [number],
        component: InputRedux,
        cellProps: { md: 6 },
      },
    ],
  },

  {
    name: "dateTimeSpecific",
    label: "date-time-specifics",
    component: ReduxFieldArray,
    reduxFormComponent: "FieldArray",
    fieldsArray: [
      {
        name: "startTime",
        label: "start",
        component: DateRangePickerRedux,
        cellProps: { md: 12, lg: 12 },
        DatePickerProps: {
          onChange: () => {},
          minDate: new Date(),
          InputFieldProps: {},
          value: { date: "", error: false },
        },
      },
    ],
  },
];
