import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarModelService from "services/carModel.service";
import { carModelActions } from "redux/slices/carModel";
import { E_CURRENT_STATUS } from "enum";

export default function UpdateCarModelForm({ id }: any) {
  const form = "AddCarModelForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carModel = useAppSelector((state) => state.carModel.carModel);

  useEffect(() => {
    CarModelService.getCarModel(id || "");

    return () => {
      dispatch(carModelActions.setCarModel(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!carModel) return;

    const {
      name,
      manufacturer,
      carCategoryId,
      carImage,
      numberOfSeats,
      numberOfBags,
      numberOfDoors,
      transmission,
      isAirConditioned,
      acrissCode,
      currentStatus,
    } = carModel;

    dispatch(
      change(form, "currentStatus", currentStatus === E_CURRENT_STATUS.ACTIVE)
    );
    dispatch(change(form, "name", name));
    dispatch(change(form, "manufacturer", manufacturer));
    dispatch(change(form, "carCategoryId", carCategoryId?._id));
    dispatch(change(form, "carImage", carImage));
    dispatch(change(form, "numberOfSeats", numberOfSeats));
    dispatch(change(form, "numberOfBags", numberOfBags));
    dispatch(change(form, "numberOfDoors", numberOfDoors));
    dispatch(change(form, "transmission", transmission));
    dispatch(change(form, "isAirConditioned", isAirConditioned));
    dispatch(change(form, "acrissCode", acrissCode));
  }, [carModel, navigate, dispatch]);

  return null;
}
