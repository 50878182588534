import SuppliersList from "./SuppliersList";
import { Container } from "@mui/material";
import Button from "components/atoms/Button";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Suppliers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <Container maxWidth="xl">
        <PageHeader
          title={t("suppliers")}
          renderRight={
            <Button variant="contained" onClick={() => navigate("/add-supplier")}>
              {t("add-supplier")}
            </Button>
          }
        />
        <SuppliersList showFilters />
      </Container>
    </div>
  );
}
