import InputRedux from "components/molecules/InputRedux";
import SelectRedux from "components/molecules/SelectRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { email, phone, required, requiredPhone } from "utils/validate.util";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";

export { default } from "./AddUserForm";

export enum E_ROLES {
  COMFY_ADMIN = "comfy_admin",
  COMFY_SALES_MANAGER = "comfy_sales_manager",
  COMFY_OPERATIONS_MANAGER = "comfy_operations_manager",
  SUPPLIER_ADMIN = "supplier_admin",
  SUPPLIER_BRANCH_MANAGER = "supplier_branch_manager",
  COMPANY_ADMIN = "company_admin",
  COMPANY_BRANCH_MANAGER = "company_branch_manager",
  USER = "user",
}

export const fields: ReduxFormField[] = [
  {
    name: "currentStatus",
    label: "is-active",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  {
    name: "title",
    label: "title",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2, lg: 2 },
    SelectProps: {
      options: [
        { value: "Mr", label: "mr" },
        { value: "Mrs", label: "mrs" },
        { value: "Ms", label: "ms" },
      ],
    },
  },
  {
    name: "firstName",
    label: "first-name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "lastName",
    label: "last-name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "phone",
    label: "phone",
    validate: [requiredPhone, phone],
    component: PhoneInputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "email",
    label: "email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
    InputProps: { type: "email" },
  },
  {
    name: "countryOfResidence",
    label: "country-Of-residence",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "dateOfBirth",
    label: "date-of-birth",
    validate: [required],
    component: DatePickerRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "roles",
    label: "roles",
    validate: [required],
    component: ComboBoxRedux,
    cellProps: { md: 6, lg: 6 },
    ComboBoxProps: {
      multiple: true,
      freeSolo: false,
      options: [
        { label: "user", value: E_ROLES.USER },
        { label: "sales-manager", value: E_ROLES.COMFY_SALES_MANAGER },
        {
          label: "operations-manager",
          value: E_ROLES.COMFY_OPERATIONS_MANAGER,
        },
        { label: "supplier-admin", value: E_ROLES.SUPPLIER_ADMIN },
        {
          label: "supplier-branch-manager",
          value: E_ROLES.SUPPLIER_BRANCH_MANAGER,
        },
        { label: "company-admin", value: E_ROLES.COMPANY_ADMIN },
        {
          label: "company-branch-manager",
          value: E_ROLES.COMPANY_BRANCH_MANAGER,
        },
      ],
    },
  },
];
