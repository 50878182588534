import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import { carSpecActions } from "redux/slices/carSpec";
import CarSpecService from "services/carSpec.service";
import Chip from "components/atoms/Chip";
import { config } from "config";
import CarSpecsFilters from "../Filters/CarSpecsFilters";
import { useTranslation } from "react-i18next";

export default function CarSpecsList({ showFilters }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const carSpecs = useAppSelector((state) => state.carSpec.carSpecs);
  const loading = useAppSelector((state) => state.carSpec.loading);
  const filters = useAppSelector((state) => state.carSpec.filters);
  const refresh = useAppSelector((state) => state.carSpec.refresh);
  // const refreshLoader = useAppSelector((state) => state.carSpec.refreshLoader);
  const totalCount = useAppSelector((state) => state.carSpec.totalCount);
  const totalPages = Math.ceil(
    totalCount / (filters.pageSize ?? config.PAGE_SIZE)
  );

  useEffect(() => {
    return () => {
      dispatch(carSpecActions.resetPage());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = showFilters ? filters : null;
    CarSpecService.getCarSpecs(data);
  }, [filters, refresh, showFilters]);

  return (
    <>
      <TableContainer>
        {showFilters && <CarSpecsFilters />}
        <Box
          p={3}
          pt={0}
          bgcolor={"#ffffff"}
          borderRadius={"4px"}
          border={"1px solid #d3d3d3"}
        >
          <Table
            aria-label="customized table"
            sx={{
              minWidth: "100%",
              borderSpacing: "0 10px",
              borderBottomWidth: "0px",
              borderCollapse: "separate",
            }}
          >
            <TableLoadingWrapper
              coloumns={7}
              loading={loading}
              length={carSpecs.length < 1 && loading ? 0 : carSpecs.length}
              message={`${t("no-one-connected-with-rental-services-yet")}`}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("ID")}</StyledTableCell>
                  <StyledTableCell>{t("acriss-code")}</StyledTableCell>
                  <StyledTableCell>{t("air-conditioned")}</StyledTableCell>
                  <StyledTableCell>{t("transmission")}</StyledTableCell>
                  <StyledTableCell>{t("seats")}</StyledTableCell>
                  <StyledTableCell>{t("bags")}</StyledTableCell>
                  <StyledTableCell>{t("doors")}</StyledTableCell>
                  <StyledTableCell>{t("status")}</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carSpecs.map((carSpec, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{carSpec?.docNumber}</StyledTableCell>
                    <StyledTableCell>{carSpec?.acrissCode}</StyledTableCell>
                    <StyledTableCell>
                      {carSpec?.isAirConditioned ? t("yes") : t("no")}
                    </StyledTableCell>
                    <StyledTableCell>{carSpec?.transmission}</StyledTableCell>
                    <StyledTableCell>{carSpec?.numberOfSeats}</StyledTableCell>
                    <StyledTableCell>{carSpec?.numberOfBags}</StyledTableCell>
                    <StyledTableCell>{carSpec?.numberOfDoors}</StyledTableCell>
                    <StyledTableCell>
                      <Chip status={t(carSpec?.currentStatus)} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link
                        className="detail-link"
                        to={`/car-specs-details/${carSpec._id}`}
                      >
                        {t("details")}
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TableLoadingWrapper>
          </Table>
          {/* {!refreshLoader && showFilters && ( */}
          <Stack alignItems={"center"}>
            <Pagination
              variant="outlined"
              shape="rounded"
              page={filters.page}
              count={Math.ceil(totalPages ?? filters.pageSize)}
              onChange={(_e, page) =>
                dispatch(carSpecActions.setPage({ page }))
              }
            />
          </Stack>
          {/* )} */}
        </Box>
      </TableContainer>
    </>
  );
}
