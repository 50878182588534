import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarService from "services/car.service";
import { carActions } from "redux/slices/car";
import { E_CURRENT_STATUS } from "enum";

export default function UpdateCarForm({ id, initialize }: any) {
  const dispatch = useAppDispatch();
  const car = useAppSelector((state) => state.car.car);

  useEffect(() => {
    CarService.getCar(id || "");
    return () => {
      dispatch(carActions.setCar(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (car) {
      initialize({
        currentStatus:
          car.currentStatus === E_CURRENT_STATUS.ACTIVE ? true : false,
        isAirConditioned: car.isAirConditioned,
        availableNow: car.availableNow,
        defaultAvailability: car.defaultAvailability,
        transmission: car.transmission,
        fuelType: car.fuelType,
        includes: car.includes.map((tag: any) => {
          return { value: tag, label: tag };
        }),
        tags: car?.tags.map((tag: any) => {
          return { value: tag, label: tag };
        }),
        numberOfCars: car.numberOfCars,
        manufacturingYear: car.manufacturingYear,
        numberOfSeats: car.numberOfSeats,
        numberOfBags: car.numberOfBags,
        numberOfDoors: car.numberOfDoors,
        isUnlimitedKM: car.isUnlimitedKM,
        dailyRate: car.dailyRate,
        weeklyRate: car.weeklyRate,
        monthlyRate: car.monthlyRate,
        extraKMCost: car.extraKMCost,
        freeKMPerDay: car.freeKMPerDay,
        branchId: car.branchId._id,
        modelId: car.modelId._id,
        supplierId: car.supplierId._id,
      });
    }
  }, [car, dispatch]);

  return null;
}
