import { Stack } from "@mui/material";
import { UserMenu } from "../user-menu/UserMenu";
import { LanguageMenu } from "../language-menu/LanguageMenu";

export const ToolbarElements = () => {
  return (
    <Stack direction={"row"} spacing={2}>
      <LanguageMenu />
      <UserMenu />
    </Stack>
  );
};
