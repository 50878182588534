import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { carActions } from "redux/slices/car";

export default function CarsFilters() {
  const form = "CarsFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    let data: any = { page: 1, pageSize: default_page_size };

    if (values.isAirConditioned)
      data.isAirConditioned = values.isAirConditioned;
    if (values.availableNow) data.availableNow = values.availableNow;
    if (values.defaultAvailability)
      data.defaultAvailability = values.defaultAvailability;
    if (values.docNumber) data.docNumber = values.docNumber;
    if (values.modelId) data.modelId = values.modelId;
    if (values.supplierId) data.supplierId = values.supplierId;
    if (values.dailyRate) data.dailyRate = values.dailyRate;
    if (values.manufacturingYear)
      data.manufacturingYear = values.manufacturingYear;
    if (values.numberOfSeats) data.numberOfSeats = values.numberOfSeats;
    if (values.numberOfDoors) data.numberOfDoors = values.numberOfDoors;
    if (values.numberOfBags) data.numberOfBags = values.numberOfBags;
    if (values.pageSize) data.pageSize = values.pageSize;

    dispatch(carActions.setFilters(data));
  };
  const formFields = [
    "isAirConditioned",
    "availableNow",
    "defaultAvailability",
    "docNumber",
    "modelId",
    "supplierId",
    "dailyRate",
    "manufacturingYear",
    "numberOfSeats",
    "numberOfDoors",
    "numberOfBags",
    "pageSize",
  ];

  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(carActions.resetFilters())}
        totalCountSelector={(state: any) => state.car.totalCount}
      />
    </div>
  );
}
