// import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";

export { default } from "./AddCouponsForm";

export const fields: ReduxFormField[] = [
  {
    name: "type",
    label: "coupon-type",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 12, lg: 12 },
    SelectProps: {
      options: [
        { value: "unique_code", label: "unique-code" },
        { value: "common_code", label: "common-code" },
      ],
    },
  },
];
