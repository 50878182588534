import ReactSelect from "components/atoms/ReactSelect";

export default function SelectReactRedux({
  input,
  handleBlur,
  handleFocus,
  SelectProps,
  handleChange,
  meta: { error, touched, invalid },
  ...rest
}: any) {

  return (
    <ReactSelect
      {...SelectProps}
      {...rest}
      input={input}
      meta={{ error, touched, invalid }}
      helperText={touched && invalid && error}
      error={touched && invalid && error && true}
      onChange={(value: any) => {
        input.onChange(value);
        handleChange?.(value);
      }}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        handleBlur?.(e);
        e.preventDefault();
      }}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
        handleFocus?.(e);
        e.preventDefault();
      }}
    />
  );
}
