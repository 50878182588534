import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import { supplierActions } from "redux/slices/supplier";
import SupplierService from "services/supplier.service";
import Chip from "components/atoms/Chip";
import { CompanyListProps } from "pages/Companies/CompaniesList";
import { config } from "config";
import SuppliersFilters from "../Filters/EmployeesFilters";
import { useTranslation } from "react-i18next";

export default function SuppliersList({ showFilters }: CompanyListProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const suppliers = useAppSelector((state) => state.supplier.suppliers);
  const loading = useAppSelector((state) => state.supplier.loading);
  const filters = useAppSelector((state) => state.supplier.filters);
  const refresh = useAppSelector((state) => state.supplier.refresh);
  const refreshLoader = useAppSelector((state) => state.supplier.refreshLoader);
  const totalCount = useAppSelector((state) => state.supplier.totalCount);
  const totalPages = Math.ceil(
    totalCount / (filters.pageSize ?? config.PAGE_SIZE)
  );

  useEffect(() => {
    return () => {
      dispatch(supplierActions.resetPage());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = showFilters ? filters : null;
    SupplierService.getSuppliers(data);
  }, [filters, refresh, showFilters]);

  return (
    <TableContainer>
      {showFilters && <SuppliersFilters />}
      <Box
        p={3}
        pt={0}
        bgcolor={"#ffffff"}
        borderRadius={"4px"}
        border={"1px solid #d3d3d3"}
      >
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            coloumns={7}
            loading={loading}
            length={suppliers.length < 1 && loading ? 0 : suppliers.length}
            message={`${t("no-one-connected-with-rental-services-yet")}`}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("SID")}</StyledTableCell>
                <StyledTableCell>{t("logo")}</StyledTableCell>
                <StyledTableCell>{t("name")}</StyledTableCell>
                <StyledTableCell>{t("contact")}</StyledTableCell>
                <StyledTableCell>{t("legal-info")}</StyledTableCell>
                <StyledTableCell>{t("vehicles")}</StyledTableCell>
                <StyledTableCell>{t("status")}</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers.map((supplier, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{supplier?.docNumber}</StyledTableCell>
                  <StyledTableCell>
                    <img
                      src={supplier?.businessLogo}
                      alt={supplier?.legalName}
                      style={{ maxWidth: "100px" }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack>
                      {supplier?.name} <br />
                      {supplier?.legalName}
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell>
                    {supplier?.mainContactPerson} <br />+
                    {supplier.phoneNumber?.dialingCode.concat(
                      supplier.phoneNumber?.number
                    )}
                    <br />
                    {supplier.email}
                  </StyledTableCell>
                  <StyledTableCell>
                    {t("tax-ID")}: {supplier?.taxId} <br />
                    {t("registration")}: {supplier?.registrationNumber} <br />
                    {/* {supplier?.address?.formattedAddress} */}
                  </StyledTableCell>
                  <StyledTableCell>{supplier.numberOfVehicles}</StyledTableCell>
                  <StyledTableCell>
                    <Chip status={t(supplier?.currentStatus)} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link
                      className="detail-link"
                      to={`/supplier-details/${supplier._id}`}
                    >
                      {t("details")}
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
        {!refreshLoader && showFilters && (
          <Stack alignItems={"center"}>
            <Pagination
              variant="outlined"
              shape="rounded"
              page={filters.page}
              count={Math.ceil(totalPages ?? filters.pageSize)}
              onChange={(_e, page) =>
                dispatch(supplierActions.setPage({ page }))
              }
            />
          </Stack>
        )}
      </Box>
    </TableContainer>
  );
}
