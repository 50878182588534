import { fields } from ".";
import { Field, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { useAppSelector } from "redux/hooks";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import SelectSupplier from "components/organisms/SelectSupplier";
import CarService from "services/car.service";
import SelectCarModel from "components/organisms/SelectCarModel";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import { useTranslation } from "react-i18next";

function AddCarExtraForm({ handleSubmit }: any) {
  // const modelOptions = useAppSelector((state) => state.car.supplierCars);
  const { t } = useTranslation();
  const supplierId = useAppSelector(
    (state) => state.form?.["AddCarExtraForm"]?.values?.supplierId
  );

  useEffect(() => {
    if (supplierId) CarService.getSupplierCars(supplierId);
  }, [supplierId]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} mb={2}>
          <Grid item md={12}>
            <Field
              name="currentStatus"
              label={t("current-status")}
              component={CheckboxRedux}
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="supplierId"
              label={t("suppliers")}
              component={SelectSupplier}
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="modelId"
              label={t("car-model")}
              component={SelectCarModel}
            />
          </Grid>
        </Grid>
        <ReduxFormFields fields={fields} />

        <br />
        <Button variant="contained" type="submit">
          {t('submit')}
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({
  form: "AddCarExtraForm",
  initialValues: { currentStatus: true },
})(AddCarExtraForm);
