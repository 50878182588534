import InputRedux from "components/molecules/InputRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import {
  email,
  number,
  phone,
  required,
  requiredAutoComplete,
  requiredPhone,
} from "utils/validate.util";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import FileUploadRedux from "components/molecules/FileUploadRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";

export { default } from "./AddSupplierForm";

export const fields: ReduxFormField[] = [
  {
    name: "currentStatus",
    label: "is-active",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  {
    name: "name",
    label: "name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 5, lg: 5 },
  },
  {
    name: "legalName",
    label: "legal-name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 5, lg: 5 },
  },
  {
    name: "mainContactPerson",
    label: "contact-person-name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "numberOfVehicles",
    label: "number-Of-vehicles",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "taxId",
    label: "tax-ID",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "registrationNumber",
    label: "registration-number",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "phone",
    label: "phone",
    validate: [requiredPhone, phone],
    component: PhoneInputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "email",
    label: "email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
    InputProps: { type: "email" },
  },
  {
    name: "businessLogo",
    label: "business-logo",
    validate: [required],
    component: FileUploadRedux,
    cellProps: { md: 12, lg: 12 },
  },
  {
    name: "address",
    label: "address",
    validate: [required, requiredAutoComplete],
    component: AutoCompleteRedux,
    cellProps: { md: 12, lg: 12 },
  },
  {
    name: "description",
    label: "description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 12, lg: 12 },
    InputProps: { multiline: true, rows: 5 },
  },
];
