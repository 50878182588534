import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddCarForm from "./AddCarForm";
import CarService from "services/car.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { E_CURRENT_STATUS } from "enum";
import { useTranslation } from "react-i18next";
// import { useEffect } from "react";
// import CarModelService from "services/carModel.service";

export default function AddCar() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const car = useAppSelector((state) => state.car.car);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };

    const { tags, includes, currentStatus } = data;

    if (tags && tags.length > 0) {
      data.tags = tags.map((tag: any) => tag.value);
    }

    if (includes && includes.length > 0) {
      data.includes = includes.map((tag: any) => tag.value);
    }

    currentStatus
      ? (data.currentStatus = E_CURRENT_STATUS.ACTIVE)
      : (data.currentStatus = E_CURRENT_STATUS.INACTIVE);

    if (id) {
      CarService.updateCar(`${id}`, data, navigate);
    } else CarService.addCar(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && car ? `/car-details/${id}` : "/cars"}`}
          title={`${t("back-to")} ${id && car ? t("car-details") : t("cars")} `}
        />
        <PageHeader title={`${id ? t("update") : t("add")} ${t("car")}`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && car === "Not found" ? (
            <p>Car Not Found</p>
          ) : (
            <AddCarForm onSubmit={handleSubmit} />
          )}
        </div>
      </Container>
    </div>
  );
}
