import { useEffect } from "react";
import {
  Chip as MUIChip,
  Container,
  Grid,
  Stack,
  Paper,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import CarService from "services/car.service";
import { carActions } from "redux/slices/car";
import { useTranslation } from "react-i18next";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

export default function CarDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const car = useAppSelector((state) => state.car.car);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    CarService.getCar(id || "");
    return () => {
      dispatch(carActions.setCar(null));
    };
  }, [id, dispatch]);

  const DetailField = ({
    label,
    value,
    fullWidth = false,
  }: {
    label: string;
    value: React.ReactNode;
    fullWidth?: boolean;
  }) => (
    <Box sx={{ mb: 1.5, width: fullWidth ? "100%" : "auto" }}>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        sx={{ mb: 0.5, fontSize: "0.875rem" }}
      >
        {label}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "0.9375rem" }}>
        {value}
      </Typography>
    </Box>
  );

  const StatusIndicator = ({ available }: { available: boolean }) => (
    <Stack direction="row" alignItems="center" spacing={1}>
      {available ? (
        <CheckCircleRoundedIcon color="success" sx={{ fontSize: "1.25rem" }} />
      ) : (
        <CancelRoundedIcon color="error" sx={{ fontSize: "1.25rem" }} />
      )}
      <Typography
        variant="body2"
        sx={{ color: available ? "success.main" : "error.main" }}
      >
        {available ? t("yes") : t("no")}
      </Typography>
    </Stack>
  );

  const ListDisplay = ({ items }: { items: string[] }) => (
    <Stack direction="row" flexWrap="wrap" gap={1}>
      {items?.map((item, index) => (
        <MUIChip
          key={index}
          label={item}
          size="small"
          variant="outlined"
          sx={{
            borderColor: "#e0e0e0",
            "&:hover": {
              borderColor: "#bdbdbd",
            },
          }}
        />
      ))}
    </Stack>
  );

  return (
    <Container maxWidth="xl">
      <GoBack path="/cars" title={t("back-to-cars")} />
      <PageHeader
        title={"car-details"}
        renderRight={
          <Button
            variant="contained"
            onClick={() => navigate(`/update-sup-car/${car._id}`)}
          >
            {t("edit")}
          </Button>
        }
      />
      {loading && <CircleLoader />}
      {!loading && !car ? (
        <Typography variant="h6" color="error">
          {t("car-not-found")}
        </Typography>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {/* Basic Information and Availability Section */}
            <Grid item xs={12} md={5}>
              <Paper
                elevation={0}
                sx={{
                  p: 2.5,
                  height: "100%",
                  border: "1px solid #e0e0e0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    mb: 2.5,
                    backgroundColor: "#f8f9fa",
                    p: 2,
                    borderRadius: 1,
                  }}
                >
                  <img
                    src={car?.modelId?.carImage}
                    alt={car?.modelId?.name}
                    style={{
                      height: "180px",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ mb: 2 }}
                  >
                    <Typography variant="h6" sx={{ fontSize: "1.1rem" }}>
                      {t("basic-information")}
                    </Typography>
                    <Chip status={t(car?.currentStatus)} />
                  </Stack>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <DetailField label={t("ID")} value={car?.docNumber} />
                    </Grid>
                    <Grid item xs={5}>
                      <DetailField
                        label={t("supplier")}
                        value={
                          <Link
                            to={`/supplier-details/${car?.supplierId?._id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <MUIChip
                              label={t(car?.supplierId?.name)}
                              size="small"
                              variant="filled"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                color: "text.primary",
                                "&:hover": {
                                  backgroundColor: "#e0e0e0",
                                },
                              }}
                            />
                          </Link>
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <DetailField
                        label={t("branch")}
                        value={
                          <Link
                            to={`/branch-details/${car?.branchId?._id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <MUIChip
                              label={t(car?.branchId?.name)}
                              size="small"
                              variant="filled"
                              sx={{
                                backgroundColor: "#f5f5f5",
                                color: "text.primary",
                                "&:hover": {
                                  backgroundColor: "#e0e0e0",
                                },
                              }}
                            />
                          </Link>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailField
                        label={t("name")}
                        value={`${car?.modelId?.name} - ${car?.manufacturingYear}`}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailField
                        label={t("manufacturer")}
                        value={car?.modelId?.manufacturer}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailField
                        label={t("acriss-code")}
                        value={car?.modelId?.acrissCode}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Divider sx={{ my: 2 }} />

                  <Typography variant="h6" sx={{ mb: 2, fontSize: "1.1rem" }}>
                    {t("car-availability")}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DetailField
                        label={t("available-now")}
                        value={
                          <StatusIndicator available={car?.availableNow} />
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DetailField
                        label={t("default-availability")}
                        value={
                          <StatusIndicator
                            available={car?.defaultAvailability}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>

            {/* Specifications and Pricing Section */}
            <Grid item xs={12} md={7}>
              <Paper
                elevation={0}
                sx={{
                  p: 2.5,
                  height: "100%",
                  border: "1px solid #e0e0e0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6" sx={{ mb: 2, fontSize: "1.1rem" }}>
                  {t("specifications")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4}>
                    <DetailField
                      label={t("number-Of-seats")}
                      value={car?.numberOfSeats}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DetailField
                      label={t("number-Of-bags")}
                      value={car?.numberOfBags}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DetailField
                      label={t("number-Of-doors")}
                      value={car?.numberOfDoors}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DetailField label={t("fuel-type")} value={car?.fuelType} />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DetailField
                      label={t("transmission")}
                      value={car?.transmission}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DetailField
                      label={t("air-conditioned")}
                      value={car?.isAirConditioned ? t("yes") : t("no")}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DetailField
                      label={t("unlimited-KM")}
                      value={car?.isUnlimitedKM ? t("yes") : t("no")}
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <DetailField
                      label={t("free-KM-per-day")}
                      value={car?.freeKMPerDay}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" sx={{ mb: 2, fontSize: "1.1rem" }}>
                  {t("tags")}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <ListDisplay items={car?.tags || []} />
                </Box>

                <Typography variant="h6" sx={{ mb: 2, fontSize: "1.1rem" }}>
                  {t("includes")}
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <ListDisplay items={car?.includes || []} />
                </Box>

                <Divider sx={{ my: 2 }} />

                <Typography variant="h6" sx={{ mb: 2, fontSize: "1.1rem" }}>
                  {t("car-pricing")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DetailField
                      label={t("daily-rate")}
                      value={`${car?.dailyRate?.toFixed(2)} SAR`}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailField
                      label={t("weekly-rate")}
                      value={`${car?.weeklyRate?.toFixed(2)} SAR`}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailField
                      label={t("monthly-rate")}
                      value={`${car?.monthlyRate?.toFixed(2)} SAR`}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DetailField
                      label={t("extra-KM-cost")}
                      value={`${car?.extraKMCost?.toFixed(2)} SAR`}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
}
