import { dateRangeFilter, email } from "utils/validate.util";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import DateRangePickerRedux from "components/molecules/DateRangePickerRedux";
import SelectSupplier from "components/organisms/SelectSupplier";
import SelectCar from "components/organisms/SelectCar";
import SelectBranch from "components/organisms/SelectBranch";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import SelectRedux from "components/molecules/SelectRedux";
import { E_ROLES } from "pages/AddUser/AddUserForm";
import SelectCompany from "components/organisms/SelectCompany";
import SelectFranchise from "components/organisms/SelectFranchise";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";
import { InsServiceOpts } from "pages/AddInsurance/AddInsuranceForm";
import { E_BUDGET_REQUEST_STATUS, E_CURRENT_STATUS } from "enum";

const formInputs: any = {
  docNumber: {
    name: "docNumber",
    label: "ID",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  isBusinessBooking: {
    name: "isBusinessBooking",
    label: "business-booking",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  currentStatus: {
    name: "currentStatus",
    label: "status",
    component: SelectRedux,
    cellProps: { sm: 6, md: 4 },
    SelectProps: {
      options: [
        { label: E_CURRENT_STATUS.ACTIVE, value: E_CURRENT_STATUS.ACTIVE },
        { label: E_CURRENT_STATUS.INACTIVE, value: E_CURRENT_STATUS.INACTIVE },
      ],
    },
  },
  requestStatus: {
    name: "requestStatus",
    label: "request-status",
    component: SelectRedux,
    cellProps: { sm: 6, md: 4 },
    SelectProps: {
      options: [
        {
          label: E_BUDGET_REQUEST_STATUS.PENDING,
          value: E_BUDGET_REQUEST_STATUS.PENDING,
        },
        {
          label: E_BUDGET_REQUEST_STATUS.ACCEPTED,
          value: E_BUDGET_REQUEST_STATUS.ACCEPTED,
        },
        {
          label: E_BUDGET_REQUEST_STATUS.REJECTED,
          value: E_BUDGET_REQUEST_STATUS.REJECTED,
        },
      ],
    },
  },
  isUnlimitedKM: {
    name: "isUnlimitedKM",
    label: "unlimited-KM",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  supplierId: {
    name: "supplierId",
    label: "supplier",
    component: SelectSupplier,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  modelId: {
    name: "modelId",
    label: "car",
    component: SelectCar,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  pickupBranchId: {
    name: "pickupBranchId",
    label: "pickup-branch",
    component: SelectBranch,

    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small", form: "BookingFiltersForm" },
  },
  dropOffBranchId: {
    name: "dropOffBranchId",
    label: "drop-Off-branch",
    component: SelectBranch,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small", form: "BookingFiltersForm" },
  },
  firstName: {
    name: "firstName",
    label: "first-name",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  lastName: {
    name: "lastName",
    label: "last-name",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  email: {
    name: "email",
    label: "email",
    validate: [email],
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { type: "email", size: "small" },
  },
  phoneNumber: {
    name: "phoneNumber",
    label: "phone",
    component: PhoneInputRedux,
    cellProps: { sm: 6, md: 4 },
  },
  date: {
    name: "date",
    label: "date",
    validate: [dateRangeFilter],
    component: DateRangePickerRedux,
    cellProps: { md: 8 },
    rowProps: { spacing: 0.5 },
    DateRangePickerProps: {
      onChange: () => {},
      InputFieldProps: {},
      value: { date: ["", ""], error: ["", ""] },
    },
    InputProps: { size: "small" },
  },
  countryOfResidence: {
    name: "countryOfResidence",
    label: "country-Of-residence",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  roles: {
    name: "roles",
    label: "roles",
    component: SelectRedux,
    cellProps: { sm: 6, md: 4 },
    SelectProps: {
      options: [
        { label: "user", value: E_ROLES.USER },
        { label: "sales-manager", value: E_ROLES.COMFY_SALES_MANAGER },
        {
          label: "operations-manager",
          value: E_ROLES.COMFY_OPERATIONS_MANAGER,
        },
        { label: "supplier-admin", value: E_ROLES.SUPPLIER_ADMIN },
        {
          label: "supplier-branch-manager",
          value: E_ROLES.SUPPLIER_BRANCH_MANAGER,
        },
        { label: "company-admin", value: E_ROLES.COMPANY_ADMIN },
        {
          label: "company-branch-manager",
          value: E_ROLES.COMPANY_BRANCH_MANAGER,
        },
      ],
    },
  },

  excludes: {
    name: "excludes",
    label: "excludes",
    component: ComboBoxRedux,
    cellProps: { sm: 6, md: 4 },
    ComboBoxProps: {
      multiple: true,
      freeSolo: true,
      options: InsServiceOpts,
    },
  },

  dateOfBirth: {
    name: "dateOfBirth",
    label: "date-of-birth",
    component: DatePickerRedux,
    cellProps: { sm: 6, md: 4 },
  },

  name: {
    name: "name",
    label: "name",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  title: {
    name: "title",
    label: "title",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  manufacturer: {
    name: "manufacturer",
    label: "manufacturer",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  acrissCode: {
    name: "acrissCode",
    label: "acrissCode",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  priorityNumber: {
    name: "priorityNumber",
    label: "priority-number",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  discountType: {
    name: "discountType",
    label: "discount-type",
    component: SelectRedux,
    cellProps: { sm: 6, md: 4 },
    SelectProps: {
      options: [
        { label: "Fixed", value: "fixed" },
        { label: "Percentage", value: "percentage" },
      ],
    },
  },
  discountValue: {
    name: "discountValue",
    label: "discount-value",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  priceType: {
    name: "priceType",
    label: "price-type",
    component: SelectRedux,
    cellProps: { sm: 6, md: 4 },
    SelectProps: {
      options: [
        { label: "Fixed Price", value: "fixed_price" },
        { label: "Fixed Percentage", value: "fixed_percentage" },
        {
          label: "Shared Percentage",
          value: "shared_percentage",
        },
      ],
    },
  },
  priceValue: {
    name: "priceValue",
    label: "price-value",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  description: {
    name: "description",
    label: "description",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  minOrderLimit: {
    name: "minOrderAmount",
    label: "min-order-amount",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  emailSpecific: {
    name: "emailSpecific",
    label: "email-specific",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  locationSpecific: {
    name: "locationSpecific",
    label: "location-specific",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  modelSpecific: {
    name: "modelSpecific",
    label: "model-specific",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  dateTimeSpecific: {
    name: "dateTimeSpecific",
    label: "date-time-specific",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 4 },
  },
  isNewUserOnly: {
    name: "isNewUserOnly",
    label: "new-user-only",
    component: CheckboxRedux,
    cellProps: { sm: 6, md: 8 },
  },

  dateOfIncorporation: {
    name: "dateOfIncorporation",
    label: "incorporation-date",
    component: DatePickerRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  mainContactPerson: {
    name: "mainContactPerson",
    label: "contact-person",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  registrationNumber: {
    name: "registrationNumber",
    label: "registration-number",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  companyId: {
    name: "companyId",
    label: "company",
    component: SelectCompany,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },

  franchiseCode: {
    name: "franchiseCode",
    label: "franchise-code",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  dateOfEstablishment: {
    name: "dateOfEstablishment",
    label: "establishment-date",
    component: DatePickerRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  employeeNumber: {
    name: "employeeNumber",
    label: "employee-number",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  franchiseId: {
    name: "franchiseId",
    label: "franchise",
    component: SelectFranchise,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  designation: {
    name: "designation",
    label: "designation",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  taxId: {
    name: "taxId",
    label: "tax-ID",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  branchCode: {
    name: "branchCode",
    label: "branch-code",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  isAirConditioned: {
    name: "isAirConditioned",
    label: "air-conditioned",
    component: CheckboxRedux,
    cellProps: { md: 4 },
  },
  availableNow: {
    name: "availableNow",
    label: "available-now",
    component: CheckboxRedux,
    cellProps: { md: 4 },
  },
  defaultAvailability: {
    name: "defaultAvailability",
    label: "default-availability",
    component: CheckboxRedux,
    cellProps: { md: 4 },
  },
  dailyRate: {
    name: "dailyRate",
    label: "daily-rate",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  weeklyRate: {
    name: "weeklyRate",
    label: "weekly-rate",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  monthlyRate: {
    name: "monthlyRate",
    label: "monthly-rate",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  manufacturingYear: {
    name: "manufacturingYear",
    label: "manufacturing-year",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  extraKMCost: {
    name: "extraKMCost",
    label: "extra-KM-cost",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  freeKMPerDay: {
    name: "freeKMPerDay",
    label: "free-KM-per-day",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  numberOfSeats: {
    name: "numberOfSeats",
    label: "number-Of-seats",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  numberOfBags: {
    name: "numberOfBags",
    label: "number-Of-bags",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  numberOfDoors: {
    name: "numberOfDoors",
    label: "number-Of-doors",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  pageSize: {
    name: "pageSize",
    label: "results-per-page",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
};

export const fields = (keys: string[]): ReduxFormField[] => {
  return keys.map((key: string) => formInputs[key]) as ReduxFormField[];
};
