import { Container, Stack, Grid } from "@mui/material";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./PromotionDetails.module.css";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import PromotionService from "services/promotion.service";
import { promotionActions } from "redux/slices/promotion";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect } from "react";
import AddCoupons from "pages/AddCoupons";
import CouponsList from "pages/Promotion/CouponsList";
import { useTranslation } from "react-i18next";

export default function PromotionDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const promotion = useAppSelector((state) => state.promotion.promotion);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    PromotionService.getPromotion(id || "");
    return () => {
      dispatch(promotionActions.setPromotion(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/promotions" title={t("back-to-promotions")} />
      <PageHeader title={t("promotion-details")} />
      {loading && <CircleLoader />}
      {id && !promotion ? (
        <p>{t("promotion-not-found")}</p>
      ) : (
        <div className="form">
          <Grid container>
            <Grid item md={6}>
              <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
                <Chip status={t(promotion?.currentStatus)} /> &nbsp;
                <Button
                  variant="text"
                  onClick={() => navigate(`/update-promotion/${promotion._id}`)}
                >
                  {t("edit")}
                </Button>
              </Stack>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("promotion-ID")}</label>
                <p>{promotion?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("title")}</label>
                <p>{promotion?.title}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("description")}</label>
                <p>{promotion?.description}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("discount-type")}</label>
                <p>{promotion?.discountType}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("discount-value")}</label>
                <p>{promotion?.discountValue?.toFixed(2)}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">{t("min-order-amount")}</label>
                <p>{promotion?.minOrderAmount?.toFixed(2)}</p>
              </div>
            </Grid>
            <Grid item md={6}>
              <AddCoupons />
            </Grid>
          </Grid>
        </div>
      )}
      <CouponsList />
    </Container>
  );
}
