import { Close } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Button from "components/atoms/Button";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import InputRedux from "components/molecules/InputRedux";
import BookingCards from "components/templates/BookingCards";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { change, Field } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { currencyFormatter } from "utils/currency_formatter.util";
import DateService from "utils/date.util";
import { calculateTotal } from "utils/price-calculate";

const UpdateBookingPricing = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [editPrice, setEditPrice] = useState(false);
  const [totalNumberOfDays, setTotalNumberOfDays] = useState(0);
  const booking = useAppSelector((state) => state.booking?.booking);
  const allExtras = useAppSelector((state) => state.addBooking.extras);

  const extras = useAppSelector(
    (state) => state.form?.["UpdateBookingForm"]?.values
  );

  const selectedExtras = allExtras?.extras?.filter(
    (extra: any) => extras?.[extra.id]
  );

  const selectedInsurance = useAppSelector(
    (state) => state.form?.["UpdateBookingForm"]?.values?.insurance
  );

  const adminTotalPrice = useAppSelector(
    (state) => state.form?.["UpdateBookingForm"]?.values?.adminTotalPrice
  );

  const dates = useAppSelector(
    (state) => state.form?.["UpdateBookingForm"]?.values?.dates
  );

  useEffect(() => {
    setTotalNumberOfDays(booking?.totalNumberOfDays);
    dispatch(
      change("UpdateBookingForm", "isPaid", booking?.paymentStatus === "paid")
    );
    dispatch(
      change(
        "UpdateBookingForm",
        "adminTotalPrice",
        (booking?.paymentDetail?.bookingNetAmount * 1.15)?.toFixed(2)
      )
    );
  }, [booking]);

  useEffect(() => {
    if (adminTotalPrice === booking?.paymentDetail?.bookingNetAmount * 1.15) {
      setEditPrice(false);
    } else {
      setEditPrice(true);
    }
  }, [booking]);

  useEffect(() => {
    if (dates?.date[0] && dates?.date[1]) {
      if (typeof dates?.date[0] === "object") {
        setTotalNumberOfDays(
          DateService.calculateDaysBetween(dates?.date[0], dates?.date[1]) ??
            booking.totalNumberOfDays
        );
      }
    }
  }, [dates]);

  useEffect(() => {
    if (booking && totalNumberOfDays) {
      if (totalNumberOfDays !== booking?.totalNumberOfDays) {
        let price: any =
          totalNumberOfDays * booking?.paymentDetail?.comfyDailyRate * 1.15;

        if (price)
          dispatch(
            change("UpdateBookingForm", "adminTotalPrice", price?.toFixed(2))
          );
      }
    }
  }, [totalNumberOfDays, booking]);

  useEffect(() => {
    if (booking && totalNumberOfDays) {
      if (totalNumberOfDays !== booking?.totalNumberOfDays) {
        let price = calculateTotal(
          adminTotalPrice
            ? Number(adminTotalPrice)
            : booking?.paymentDetail?.bookingNetAmount * 1.15,
          selectedExtras,
          allExtras?.insurances?.find(
            (ins: any) => ins.id === selectedInsurance
          )
        )?.totalSubtotal;
        localStorage.setItem("totalPrice", price);
      }
    }
  }, [
    adminTotalPrice,
    allExtras?.insurances,
    selectedExtras,
    selectedInsurance,
    totalNumberOfDays,
    booking,
  ]);

  return (
    <div style={{ position: "sticky", top: "40px" }}>
      <BookingCards>
        <Stack gap={2}>
          <Stack gap={1} direction={"row"} justifyContent={"space-between"}>
            <Typography>{t("total-number-of-days")}</Typography>
            <Typography fontWeight={600}>{totalNumberOfDays}</Typography>
          </Stack>
          <div className="card-main">
            <div className="card-box">
              {selectedExtras?.length > 0 && (
                <div style={{ marginBottom: "16px" }}>
                  {selectedExtras?.map((extra: any, index: any) => (
                    <div className="card-inner-price" key={index}>
                      <p className="card-inner-price-heading">{extra.name}</p>
                      <p className="card-inner-price-amount">
                        {extra.totalAmount?.toFixed(2)} SAR
                      </p>
                    </div>
                  ))}
                </div>
              )}

              {selectedInsurance && (
                <div style={{ marginBottom: "16px" }}>
                  <div className="card-inner-price">
                    <p className="card-inner-price-heading">
                      {
                        allExtras?.insurances?.find(
                          (ins: any) => ins.id === selectedInsurance
                        )?.name
                      }
                    </p>
                    <Typography className="card-inner-price-amount">
                      {allExtras?.insurances
                        ?.find((ins: any) => ins.id === selectedInsurance)
                        ?.totalAmount.toFixed(2)}{" "}
                      SAR
                    </Typography>
                  </div>
                </div>
              )}

              {/* {booking?.paymentDetail?.totalExtrasNetAmount > 0 && (
                <div className="card-inner-price">
                  <p className="card-inner-price-heading">Extras Net Amount</p>
                  <p className="card-inner-price-amount">
                    {booking?.paymentDetail?.totalExtrasNetAmount?.toFixed(2)}{" "}
                    SAR
                  </p>
                </div>
              )}

              {booking?.paymentDetail?.totalInsuranceNetAmount > 0 && (
                <div className="card-inner-price">
                  <p className="card-inner-price-heading">
                    Insurance Net Amount
                  </p>
                  <p className="card-inner-price-amount">
                    {booking?.paymentDetail?.totalInsuranceNetAmount?.toFixed(
                      2
                    )}{" "}
                    SAR
                  </p>
                </div>
              )} */}

              <div className="card-inner-price">
                <p className="card-inner-price-heading">
                  {t("booking-amount")}
                </p>
                <p className="card-inner-price-amount">
                  <div style={{ position: "relative" }}>
                    <Field
                      component={InputRedux}
                      name="adminTotalPrice"
                      placeholder="Add a custom price"
                    />
                    {editPrice && (
                      <Close
                        style={{
                          top: "10px",
                          right: "12px",
                          cursor: "pointer",
                          position: "absolute",
                          background: "#ffffff",
                        }}
                        onClick={() => {
                          setEditPrice(false);
                          dispatch(
                            change(
                              "UpdateBookingForm",
                              "adminTotalPrice",
                              (
                                booking?.paymentDetail?.bookingNetAmount * 1.15
                              )?.toFixed(2)
                            )
                          );
                        }}
                      />
                    )}
                  </div>
                </p>
              </div>

              {/* <div className="card-box">
                <div className="card-inner-price">
                  <p className="card-inner-price-heading">Total (inc. vat)</p>
                  <p className="card-inner-price-amount">
                    {booking?.paymentDetail?.totalAmount?.toFixed(2)} SAR
                  </p>
                </div>
              </div> */}
            </div>

            <div className="card-box">
              {booking?.paymentDetail?.totalDiscountAmount > 0 && (
                <div className="card-inner-price">
                  <p className="card-inner-price-heading">{t("discount")}</p>
                  <p className="card-inner-price-amount">
                    - {booking?.paymentDetail?.totalDiscountAmount?.toFixed(2)}{" "}
                    SAR
                  </p>
                </div>
              )}
              <div className="card-inner-price">
                <p className="card-inner-price-heading">
                  {t("total-gross-amount")}
                </p>
                <p className="card-inner-price-amount">
                  {currencyFormatter(
                    calculateTotal(
                      adminTotalPrice
                        ? Number(adminTotalPrice)
                        : booking?.paymentDetail?.bookingNetAmount * 1.15,
                      selectedExtras,
                      allExtras?.insurances?.find(
                        (ins: any) => ins.id === selectedInsurance
                      )
                    )?.totalSubtotal
                  )}
                  {/* {booking?.paymentDetail?.totalGrossAmount?.toFixed(2)} SAR */}
                </p>
              </div>
            </div>

            {/* <div className="card-box">
              <div className="card-inner-price">
                <p className="card-inner-price-heading">Price Type</p>
                <Typography textTransform={"uppercase"}>
                  {booking?.paymentDetail.planType}
                </Typography>
              </div>
              <div className="card-inner-price">
                <p className="card-inner-price-heading">Price Plan</p>
                <p>{booking?.paymentDetail?.planValue?.toFixed(2)} SAR</p>
              </div>
              <div className="card-inner-price">
                <p className="card-inner-price-heading">Supplier Amount</p>
                <p>{booking?.paymentDetail?.supplierAmount?.toFixed(2)} SAR</p>
              </div>
            </div> */}
          </div>
          <Field component={CheckboxRedux} name="isPaid" label={t("paid")} />
          <Button type="submit" size="large" variant="contained">
            {t("update-booking")}
          </Button>
        </Stack>
      </BookingCards>
    </div>
  );
};

export default UpdateBookingPricing;
