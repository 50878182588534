import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import Chip from "components/atoms/Chip";
import { branchActions } from "redux/slices/branch";
import BranchService from "services/branch.service";
import { config } from "config";
import { CompanyListProps } from "pages/Companies/CompaniesList";
import BranchesFilters from "../Filters/BranchesFilters";
import { useTranslation } from "react-i18next";

export default function BranchesList({ showFilters }: CompanyListProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const branches = useAppSelector((state) => state.branch.branches);
  const loading = useAppSelector((state) => state.branch.loading);
  const filters = useAppSelector((state) => state.branch.filters);
  const refresh = useAppSelector((state) => state.branch.refresh);
  const refreshLoader = useAppSelector((state) => state.branch.refreshLoader);
  const totalCount = useAppSelector((state) => state.branch.totalCount);
  const totalPages = Math.ceil(
    totalCount / (filters.pageSize ?? config.PAGE_SIZE)
  );

  useEffect(() => {
    return () => {
      dispatch(branchActions.resetPage());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = showFilters ? filters : null;
    BranchService.getBranches(data);
  }, [filters, refresh, showFilters]);

  return (
    <TableContainer>
      {showFilters && <BranchesFilters />}
      <Box
        p={3}
        pt={0}
        bgcolor={"#ffffff"}
        borderRadius={"4px"}
        border={"1px solid #d3d3d3"}
      >
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            coloumns={7}
            loading={loading}
            length={branches.length < 1 && loading ? 0 : branches.length}
            message="No branch has been added by any supplier"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("BID")}</StyledTableCell>
                <StyledTableCell>{t("name-code")}</StyledTableCell>
                <StyledTableCell>{t("supplier")}</StyledTableCell>
                <StyledTableCell>{t("contact")}</StyledTableCell>
                <StyledTableCell>{t("address")}</StyledTableCell>
                <StyledTableCell>{t("status")}</StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {branches.map((branch, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{branch?.docNumber}</StyledTableCell>
                  <StyledTableCell>
                    {branch.branchCode} <br />
                    {branch.name}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Link
                      to={`/supplier-details/${branch?.supplierId?._id}`}
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                    >
                      {branch?.supplierId?.name} <br />
                      {branch?.supplierId?.email}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>
                    {branch.email} <br />+
                    {branch.phoneNumber?.dialingCode.concat(
                      branch.phoneNumber?.number
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {/* {branch.address.formattedAddress} <br /> */}
                    {branch.address.city}, {branch.address.country}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Chip status={t(branch?.currentStatus)} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link
                      className="detail-link"
                      to={`/branch-details/${branch._id}`}
                    >
                      {t("details")}
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
        {!refreshLoader && showFilters && (
          <Stack alignItems={"center"}>
            <Pagination
              variant="outlined"
              shape="rounded"
              page={filters.page}
              count={Math.ceil(totalPages ?? filters.pageSize)}
              onChange={(_e, page) => dispatch(branchActions.setPage({ page }))}
            />
          </Stack>
        )}
      </Box>
    </TableContainer>
  );
}
