import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import InputRedux from "components/molecules/InputRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import SelectUser from "components/organisms/SelectUser";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { change, Field } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { email, required, requiredPhone } from "utils/validate.util";

const UpdateBookingUserForm = ({ handleSubmit }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const booking = useAppSelector((state) => state.booking.booking);
  const users = useAppSelector((state) => state.user.documents);
  const userId = useAppSelector(
    (state) => state.form?.["UpdateBookingForm"]?.values?.userId
  );

  // Track changes in any field to determine if any field has been filled
  const [filledFields, setFilledFields] = useState<Set<string>>(new Set());

  const handleFieldChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFilledFields((prevFields) => {
      const newFields = new Set(prevFields);
      value.trim().length > 0 ? newFields.add(name) : newFields.delete(name);
      return newFields;
    });
  };

  // Check if any field is filled
  const isAnyFieldFilled = filledFields.size > 0;

  useEffect(() => {
    if (userId && users?.length > 0) {
      const selectedUser = users.find((user) => user._id === userId);

      dispatch(
        change("UpdateBookingForm", "firstName", selectedUser?.firstName)
      );
      dispatch(change("UpdateBookingForm", "lastName", selectedUser?.lastName));
      dispatch(
        change("UpdateBookingForm", "phone", {
          value:
            selectedUser?.phoneNumber?.dialingCode +
            selectedUser?.phoneNumber.number,
        })
      );
      dispatch(change("UpdateBookingForm", "email", selectedUser?.email));
      dispatch(
        change("UpdateBookingForm", "billingAddress", booking?.billingAddress)
      );
    }
  }, [dispatch, userId, users]);

  return (
    <>
      {/* <form onSubmit={handleSubmit}> */}
      <Grid container spacing={2} mb={2}>
        <Grid item md={12}>
          <Typography fontWeight={600}>{t("updateuserdetails")}</Typography>
        </Grid>
        <Grid item md={6}>
          <Field
            component={SelectUser}
            name="userId"
            label={t("select-user")}
          />
        </Grid>
        <Grid item md={6}></Grid>
        <Grid item md={6}>
          <Field
            component={InputRedux}
            name="firstName"
            label={t("first-name")}
            // disabled={userId}
            validate={[required]}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            component={InputRedux}
            name="lastName"
            label={t("last-name")}
            // disabled={userId}
            validate={[required]}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            component={PhoneInputRedux}
            name="phone"
            label={t("phone-number")}
            validate={[requiredPhone]}
            // disabled={userId}
          />
        </Grid>
        <Grid item md={6}>
          <Field
            component={InputRedux}
            name="email"
            label={t("email")}
            validate={[required, email]}
            // disabled={userId}
          />
        </Grid>

        <Grid item md={12}>
          <Accordion style={{ boxShadow: "none" }}>
            <AccordionSummary
              sx={{ padding: 0 }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight={600}>{t("billing-address")}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0" }}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Field
                    component={InputRedux}
                    name="billingAddress.firstName"
                    label={t("first-name")}
                    onChange={handleFieldChange}
                    validate={isAnyFieldFilled ? required : undefined}
                  />
                </Grid>
                <Grid item md={6}>
                  <Field
                    component={InputRedux}
                    name="billingAddress.lastName"
                    label={t("last-name")}
                    onChange={handleFieldChange}
                    validate={isAnyFieldFilled ? required : undefined}
                  />
                </Grid>
                <Grid item md={12}>
                  <Field
                    component={InputRedux}
                    name="billingAddress.formattedAddress"
                    label={t("address")}
                    onChange={handleFieldChange}
                    validate={isAnyFieldFilled ? required : undefined}
                  />
                </Grid>
                <Grid item md={4}>
                  <Field
                    component={InputRedux}
                    name="billingAddress.city"
                    label={t("city")}
                    onChange={handleFieldChange}
                    validate={isAnyFieldFilled ? required : undefined}
                  />
                </Grid>
                <Grid item md={4}>
                  <Field
                    component={InputRedux}
                    name="billingAddress.zipCode"
                    label={t("zipcode")}
                    onChange={handleFieldChange}
                    validate={isAnyFieldFilled ? required : undefined}
                  />
                </Grid>
                <Grid item md={4}>
                  <Field
                    component={InputRedux}
                    name="billingAddress.country"
                    label={t("country")}
                    onChange={handleFieldChange}
                    validate={isAnyFieldFilled ? required : undefined}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      {/* <Button variant="contained">Update</Button> */}
    </>
  );
};

export default // reduxForm({ form: "UpdateBookingForm" })(
UpdateBookingUserForm;
// );
