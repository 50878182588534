import { fields } from ".";
import { reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { useTranslation } from "react-i18next";

function AddEmployeeForm({ handleSubmit }: any) {
  const { t } = useTranslation();
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={fields} />
        <br />
        <Button variant="contained" type="submit">
          {t("submit")}
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({
  form: "AddEmployeeForm",
  initialValues: { currentStatus: true },
})(AddEmployeeForm);
