import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import InsuranceService from "services/insurance.service";
import AddInsuranceForm from "./AddInsuranceForm";
import UpdateInsuranceForm from "./UpdateInsuranceForm";
import { E_CURRENT_STATUS } from "enum";
import { useTranslation } from "react-i18next";

export default function AddInsurance() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const insurance = useAppSelector((state) => state.insurance.insurance);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };

    data.currentStatus = values?.currentStatus
      ? E_CURRENT_STATUS.ACTIVE
      : E_CURRENT_STATUS.INACTIVE;
    data.zero_to_one = Number(values?.zero_to_one);
    data.one_to_two = Number(values?.one_to_two);
    data.two_to_three = Number(values?.two_to_three);
    data.three_to_four = Number(values?.three_to_four);
    data.more_than_four = Number(values?.more_than_four);
    data.loginPlatform = "admin";
    delete data.phone;

    if (id) {
      InsuranceService.updateInsurance(`${id}`, data, navigate);
    } else InsuranceService.addInsurance(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && insurance ? `/insurance-details/${id}` : "/insurance"
            }`}
          title={`${t("back-to")} ${id && insurance ? t("insurance-details") : t("insurances")
            } `}
        />
        <PageHeader title={`${id ? t("update") : t("add")} ${t("insurance")}`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && insurance === "Not found" ? (
            <p>Insurance Not Found</p>
          ) : (
            <AddInsuranceForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateInsuranceForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
