import Button from "components/atoms/Button";
import useEffectOnce from "hooks/useEffectOnce";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useTranslation } from "react-i18next";

export default function ReduxFieldArray({ fields, label, fieldsArray }: any) {
  const { t } = useTranslation();
  useEffectOnce(() => fields.push({}));

  return (
    <>
      <h2 style={{ fontSize: "18px", fontWeight: 500 }}>{t(label)}s</h2>
      {fields.map((member: string, index: number) => (
        <div
          key={index}
          style={{
            gap: "8px",
            display: "flex",
            alignItems: "end",
            marginBottom: "24px",
          }}
        >
          <ReduxFormFields member={member} fields={fieldsArray} />
          {fields.length > 1 && (
            <Button
              type="button"
              variant="contained"
              color="error"
              sx={{ height: "45px" }}
              onClick={() => fields.remove(index)}
            >
              <HighlightOffOutlinedIcon />
            </Button>
          )}
        </div>
      ))}
      <Button variant="outlined" type="button" onClick={() => fields.push({})}>
        {t("add-more")}
      </Button>
    </>
  );
}
