import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { number, required } from "utils/validate.util";
import SelectSupplier from "components/organisms/SelectSupplier";
import CheckboxRedux from "components/molecules/CheckboxRedux";

export { default } from "./AddCarPriceForm";

export const fields: ReduxFormField[] = [
  {
    name: "currentStatus",
    label: "is-active",
    component: CheckboxRedux,
    cellProps: { md: 2 },
  },
  {
    name: "isUnlimitedKM",
    label: "unlimited-KM",
    component: CheckboxRedux,
    cellProps: { md: 10 },
  },
  {
    name: "name",
    label: "name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6 },
  },
  {
    name: "supplierId",
    label: "supplier",
    validate: [required],
    component: SelectSupplier,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "currency",
    label: "currency",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "dailyRate",
    label: "daily-rate",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "weeklyRate",
    label: "weekly-rate",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "monthlyRate",
    label: "monthly-rate",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "extraKMCost",
    label: "extra-KM-cost",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "freeKMPerDay",
    label: "free-KM-per-day",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2, lg: 2 },
  },
];
