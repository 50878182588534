import CarCategoriesList from "./CarCategoriesList";
import { Container } from "@mui/material";
import Button from "components/atoms/Button";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function CarCategories() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <Container maxWidth="xl">
        <PageHeader
          title={t("car-categories")}
          renderRight={
            <Button
              variant="contained"
              onClick={() => navigate("/add-car-category")}
            >
              {t("add-category")}
            </Button>
          }
        />
        <CarCategoriesList showFilters />
      </Container>
    </div>
  );
}
