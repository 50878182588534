import {
  LanguageMenuContainer,
  LanguageMenuIconButton,
  LanguageMenuInfo,
  LanguageMenuMenu,
  UserNameIconButton,
} from "./styled";
import React, { useState } from "react";
import { Typography } from "@mui/material";
import { FaAngleDown } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import Theme from "components/templates/Theme";

export const LanguageMenu = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <LanguageMenuContainer>
      <LanguageMenuIconButton onClick={handleClick}>
        <UserNameIconButton>
          {i18n.language === "en" ? "EN" : "عربي"}
          <FaAngleDown />
        </UserNameIconButton>
      </LanguageMenuIconButton>
      <LanguageMenuMenu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <LanguageMenuInfo
          onClick={() => {
            let lang = i18n.language === "ar" ? "en" : "ar";
            localStorage.setItem("lang", lang);
            i18n.changeLanguage(lang);
            document.body.style.direction = lang === "en" ? "ltr" : "rtl";
            Theme.direction = lang === "en" ? "ltr" : "rtl";
            handleClose();
          }}
        >
          <Typography fontSize={16} color={"text.secondary"}>
            {i18n.language === "en" ? "عربي" : "English"}
          </Typography>
        </LanguageMenuInfo>
      </LanguageMenuMenu>
    </LanguageMenuContainer>
  );
};
