import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { length4, number, required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import SelectCarCategory from "components/organisms/SelectCarCategory";
import FileUploadRedux from "components/molecules/FileUploadRedux";

export { default } from "./AddCarModelForm";

export const fields: ReduxFormField[] = [
  {
    name: "currentStatus",
    label: "is-active",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  {
    name: "name",
    label: "name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "manufacturer",
    label: "manufacturer",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "carImage",
    label: "car-image",
    validate: [required],
    component: FileUploadRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "carCategoryId",
    label: "car-category",
    validate: [required],
    component: SelectCarCategory,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "isAirConditioned",
    label: "air-conditioned",
    component: CheckboxRedux,
    cellProps: { md: 2 },
  },
  {
    name: "transmission",
    label: "transmission",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "auto", label: "auto" },
        { value: "manual", label: "manual" },
      ],
    },
  },
  {
    name: "acrissCode",
    label: "acriss-code",
    validate: [required, length4],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfSeats",
    label: "number-Of-seats",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfBags",
    label: "number-Of-bags",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfDoors",
    label: "number-Of-doors",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
];
