export { default, bookingActions, bookingSlice } from "./bookingSlice";

export enum BOOKING {
  confirm = "confirm",
  inprogress = "inprogress",
  finish = "finish",
  bookings = "bookings",
}

export type BookingType = keyof typeof BOOKING;

export interface BookingLoadingPayload {
  loading: boolean;
  type: BookingType;
}

export interface SetPagePayload {
  page: number;
  type: BookingType;
}

export interface SetTotalCountPayload {
  totalCount: number;
}

export interface SetFiltersPayload {
  data: any;
  type: BookingType;
}

export interface SetBookingsPayload {
  bookings: any[];
  type: BookingType;
}

export interface NewBookingPayload {
  booking: any;
  type: BookingType;
}

export interface RemoveBookingPayload {
  id: any;
  type: BookingType;
}

export interface IBookings {
  filters: any;
  bookings: any[];
  loading: boolean;
  current_filters: any;
}

export interface UpdateBookingPayload {
  id: any;
  booking: boolean;
  type: BookingType;
}

export interface BasicBookingState {
  tab: number;
  booking: any;
  refresh: number;
  totalCount: number;
  loading: boolean;
  refreshLoader: boolean;
}

export type BookingState = BasicBookingState & {
  [key in BOOKING]: IBookings;
};

export interface AdminNotePayload {
  bookingId: string;
  note: { actionDetails: string; timestamp: string };
  type: BookingType;
}
