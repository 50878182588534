import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { length4, number, required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import FileUploadRedux from "components/molecules/FileUploadRedux";

export { default } from "./AddCarSpecsForm";

export const fields: ReduxFormField[] = [
  {
    name: "acrissCode",
    label: "acriss-code",
    validate: [required, length4],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "carImage",
    label: "car-image",
    validate: [required],
    component: FileUploadRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "unlimitedKM",
    label: "unlimited-KM",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "isAirConditioned",
    label: "air-conditioned",
    component: CheckboxRedux,
    cellProps: { md: 2 },
  },
  {
    name: "fuelType",
    label: "fuel-type",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "petrol", label: "petrol" },
        { value: "electric", label: "electric" },
        { value: "hybrid", label: "hybrid" },
      ],
    },
  },
  {
    name: "transmission",
    label: "transmission",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "auto", label: "auto" },
        { value: "Manual", label: "manual" },
      ],
    },
  },
  {
    name: "numberOfSeats",
    label: "number-Of-seats",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfBags",
    label: "number-Of-bags",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfDoors",
    label: "number-Of-doors",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
];
